import React, {useState} from "react";
import "./pricing-card.css";
import BeginnerImage1 from "../../../Assets/pricing-card/beginner1.png";
import BeginnerImage2 from "../../../Assets/pricing-card/beginner2.png";
import BeginnerImage3 from "../../../Assets/pricing-card/beginner3.png";
import AdvanceImage1 from "../../../Assets/pricing-card/advance1.png";
import AdvanceImage2 from "../../../Assets/pricing-card/advance2.png";
import AdvanceImage3 from "../../../Assets/pricing-card/advance3.png";
import MasterImage1 from "../../../Assets/pricing-card/master1.png";
import MasterImage2 from "../../../Assets/pricing-card/master2.png";
import MasterImage3 from "../../../Assets/pricing-card/master3.png";
import BuildIcon1 from "../../../Assets/pricing-card/build1.png";
import BuildIcon2 from "../../../Assets/pricing-card/build2.png";
import BuildIcon3 from "../../../Assets/pricing-card/build3.png";
import BuildIcon4 from "../../../Assets/pricing-card/build4.png";
import BuildIcon5 from "../../../Assets/pricing-card/build5.png";
import BuildIcon6 from "../../../Assets/pricing-card/build6.png";
import BuildIcon7 from "../../../Assets/pricing-card/build7.png";
import BuildIcon8 from "../../../Assets/pricing-card/build8.png";
import BuildIcon9 from "../../../Assets/pricing-card/build9.png";
import BuildIcon10 from "../../../Assets/pricing-card/build10.png";
import Achievement1 from "../../../Assets/pricing-card/achievement1.png";
import Achievement21 from "../../../Assets/pricing-card/achievement2-1.png";
import Achievement22 from "../../../Assets/pricing-card/achievement2-2.png";
import Achievement23 from "../../../Assets/pricing-card/achievement2-3.png";
import Achievement31 from "../../../Assets/pricing-card/achievement3-1.png";
import Achievement32 from "../../../Assets/pricing-card/achievement3-2.png";
import DownloadIcon from "../../../Assets/pricing-card/download.png";
import DollarIcon from "../../../Assets/pricing-card/dollar.png";
import CurriculumGrade_1_3 from "../../../Assets/pricing-card/grade_1_3.pdf";
import CurriculumGrade_4_7 from "../../../Assets/pricing-card/grade_4_7.pdf";
import CurriculumGrade_8_12 from "../../../Assets/pricing-card/grade_8_12.pdf";

// formats a number in indian format
const formatPrice = function (x) {
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

// gets the index of the curriculum based on url
const getCurriculumIndex = function () {
  let href = window.location.href;
  let origin = "/courses";
  console.log(origin);
  let items = href.split(origin + "/#");
  if (items.length > 1) {
    let id = items[1];
    if (id === "grade_1_3") return 0;
    if (id === "grade_4_7") return 1;
    if (id === "grade_8_12") return 2;
  }
  return 0;
};

// component
export default function PricingCard(props) {
  const [gradeIndex, setGradeIndex] = useState(getCurriculumIndex());
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [more1, setMore1] = useState(true);
  const [more2, setMore2] = useState(true);
  const [more3, setMore3] = useState(true);
  const cards = ["Grade 1 - 3", "Grade 4 - 7", "Grade 8 - 12"];
  const ids = ["grade_1_3", "grade_4_7", "grade_8_12"];
  const color0 = "#00b4db",
    color1 = "#f34444",
    color2 = "#e55ee5";
  let origin = "/courses";
  const locations = [
    origin + "/#grade_1_3",
    origin + "/#grade_4_7",
    origin + "/#grade_8_12",
  ];
  const beginnerImage = [BeginnerImage1, BeginnerImage2, BeginnerImage3];
  const advanceImage = [AdvanceImage1, AdvanceImage2, AdvanceImage3];
  const masterImage = [MasterImage1, MasterImage2, MasterImage3];
  const build1 = [BuildIcon1, BuildIcon5, BuildIcon9];
  const build2 = [
    [BuildIcon2, BuildIcon3],
    [BuildIcon6, BuildIcon7],
    [BuildIcon10, BuildIcon8],
  ];
  const build3 = [
    [BuildIcon4, BuildIcon5],
    [BuildIcon3, BuildIcon8],
    [BuildIcon4, BuildIcon3],
  ];
  const achievement2 = [Achievement21, Achievement22, Achievement23];
  const classes = [8, 48, 144];
  const price = [6400, 38400, 115200];
  const discountPrice = [5999, 33600, 93600];
  const off = [6, 12, 18];
  const perSession = [750, 700, 650];

  const redirectBuyNow = () => {
    let gradeSelected =
      window.location.hash.split("#")[1] === undefined
        ? "grade_1_3"
        : window.location.hash.split("#")[1];

    window.location.href = `https://www.techokids.com/#${gradeSelected}`;
  };
  // downloads the curriculum
  const onDownloadCurriculum = function (event, toDownload) {
    event.stopPropagation();
    let parent = document.getElementById(ids[gradeIndex]);
    let link = document.createElement("a");
    link.setAttribute("download", "TechoKids-curriculum.pdf");
    link.setAttribute("href", toDownload);
    parent.appendChild(link);
    link.click();
    parent.removeChild(link);
  };

  let index = getCurriculumIndex();
  if (gradeIndex !== index) setGradeIndex(index);

  // component to be rendered
  return (
    <div className="curriculum__pricing courses" id={ids[gradeIndex]}>
      <div className="pricing__header">
        <h1>Curriculum & Pricing</h1>
        <div className="pricing__header__button">
          {cards.map((card, i) => (
            <div
              className={gradeIndex === i ? "selected__price__button" : ""}
              onClick={(event) => {
                window.location.href = locations[i];
                setGradeIndex(i);
              }}
              key={i}
            >
              {card}
            </div>
          ))}
        </div>
      </div>
      <div className="pricing__card__container">
        <div className="pricing__card">
          <img
            className="pricing__card__image"
            src={beginnerImage[gradeIndex]}
            alt="beginnerImage1"
          />
          <div
            className="pricing__card__content box-shadow"
            style={{
              borderColor: selectedCardIndex === 0 ? color0 : "transparent",
              outline: selectedCardIndex === 0 ? "3px" : "0px",
            }}
            onClick={(event) => setSelectedCardIndex(0)}
          >
            <div className="pricing__card__main">
              <div className="pricing__level" style={{background: color0}}>
                <h1>Beginner</h1>
                {classes[0]} Classes
              </div>
              <div className={`pricing__card__box box${gradeIndex}1`}>
                <h1>Build</h1>
                <img src={build1[gradeIndex]} alt="buildIcon1" /> <br />
                {gradeIndex === 0 && (
                  <span>Create animation, story making, making games</span>
                )}
                {gradeIndex === 1 && (
                  <span>
                    Create animation, Build chatbot, Concepts of ML & AI
                  </span>
                )}
                {gradeIndex === 2 && (
                  <span>Logic building, Decision making</span>
                )}
              </div>
              <div
                className="pricing__extra__boxes"
                style={{display: more1 ? "block" : "none"}}
              >
                <div className={`pricing__card__box box${gradeIndex}2`}>
                  <h1>Program Highlights</h1>
                  {gradeIndex === 0 && (
                    <span>
                      Logic building, decision making, programming fundamentals,
                      Visual Storytelling, Creative Thinking
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Logic building, decision making, programming fundamentals,
                      Creative Thinking, Algorithm Design, User interactivity,
                      Basic concepts of Articifical Intelligence and Machine
                      Learning
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Programming fundamentals, Creative Thinking, Critical
                      Thinking, Programming in Python
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}3`}>
                  <h1>Benefits</h1>
                  {gradeIndex === 0 && (
                    <span>
                      Foundation, Game development, learning coding
                      methodologies
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Foundation, Game development, Machine Learning,
                      Articifical Intelligence, Bot building, learn coding
                      methodologies, Critical thinking
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Learning basics of python programming, learning coding
                      methodologies, basic of coding
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}4`}>
                  <h1>Outcome</h1>
                  {gradeIndex === 0 && (
                    <span>
                      Students problem-solving ability and creativity is
                      boosted. Also, they are able to visualize absract concepts
                      which can be applied to real world situations
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Students problem-solving ability and creativity is
                      boosted. Also they are able to visualize abstract concepts
                      which can be applied to real-world situations. Learn the
                      concept behind voice assistants like Siri, Alexa
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      They are able to visualize abstract concepts which can be
                      applied to real world situations. Students problem-solving
                      ability and creativity is boosted. Learn basics of python
                      programming and coding fundamentals.
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}5`}>
                  <h1>Achievements</h1>
                  <img
                    className="pricing__card__achievement"
                    src={Achievement1}
                    alt="achievement1"
                  />{" "}
                  <br />
                  {gradeIndex === 0 && (
                    <span>Game development Certificate</span>
                  )}
                  {gradeIndex === 1 && <span>Intro Certificate</span>}
                  {gradeIndex === 2 && <span>Intro Certificate</span>}
                </div>
              </div>
              <div
                className="pricing__card__box pricing__card__toggler"
                onClick={(event) => setMore1(!more1)}
              >
                {more1 ? "See less" : "See more"}
              </div>
              {/* <div className="pricing__card__price">
                <span className="discount__price" style={{color: color0}}>
                  &#8377; {formatPrice(discountPrice[0])}
                </span>
                <span className="actual__price">
                  &#8377; {formatPrice(price[0])}
                </span>
                <span className="discount__left__arrow"></span>
                <span className="discount__badge">
                  <span style={{fontWeight: "bold"}}>{off[0] + "%"}</span> off
                </span>
              </div>
              <div className="pricing__session__price">
                &#8377; {formatPrice(perSession[0])} per session
              </div> */}
            </div>
            <div
              className="download__currciculum"
              onClick={(event) =>
                onDownloadCurriculum(event, CurriculumGrade_1_3)
              }
            >
              <img src={DownloadIcon} alt="downloadIcon" /> Download Curriculum
            </div>
            <div
              className="buynow"
              onClick={redirectBuyNow}
              style={{
                background: selectedCardIndex === 0 ? color0 : "transparent",
                color: selectedCardIndex === 0 ? "white" : color0,
                borderColor: color0,
                borderBottomLeftRadius:
                  selectedCardIndex === 0 ? "5px" : "10px",
                borderBottomRightRadius:
                  selectedCardIndex === 0 ? "5px" : "10px",
              }}
            >
              Buy now
            </div>
          </div>
        </div>

        <div className="pricing__card">
          <img
            className="pricing__card__image"
            src={advanceImage[gradeIndex]}
            alt="beginnerImage1"
          />
          <div
            className="pricing__card__content box-shadow"
            style={{
              borderColor: selectedCardIndex === 1 ? color1 : "transparent",
              outline: selectedCardIndex === 1 ? "3px" : "0px",
            }}
            onClick={(event) => setSelectedCardIndex(1)}
          >
            <div className="pricing__card__main">
              <div className="pricing__level" style={{background: color1}}>
                <h1>Advance</h1>
                {classes[1]} Classes
              </div>
              <div className={`pricing__card__box box${gradeIndex}1`}>
                <h1>Build</h1>
                <img src={build2[gradeIndex][0]} alt="buildIcon20" />
                <img src={build2[gradeIndex][1]} alt="buildIcon21" /> <br />
                {gradeIndex === 0 && (
                  <span>
                    Logic building, decision making, programming fundamentals,
                    Visual Storytelling, Creative Thinking
                  </span>
                )}
                {gradeIndex === 1 && (
                  <span>Create multi-page website, logic building</span>
                )}
                {gradeIndex === 2 && (
                  <span>Create animation in python, Logic building</span>
                )}
              </div>
              <div
                className="pricing__extra__boxes"
                style={{display: more2 ? "block" : "none"}}
              >
                <div className={`pricing__card__box box${gradeIndex}2`}>
                  <h1>Program Highlights</h1>
                  <span className="homapage__beginner__bagde">Beginner +</span>
                  {gradeIndex === 0 && (
                    <span>
                      Algorithm design, Loops and Nested Loops, Block
                      Sequencing, Conditionals, User interactivity, functions
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Frontend develoment, Web designing, data structures,
                      complex problem solving, Nested and complex loop handling,
                      Advance designing and development
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Algorithm design, Loops and Nested Loops, and
                      Conditionals, User interactivity, functions, Logic
                      building, decision making, data structures, complex
                      problem solving, web scrapping
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}3`}>
                  <h1>Benefits</h1>
                  {gradeIndex === 0 && (
                    <span>
                      App development, Game development, Critical thinking
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Critical thinking, learn website designing, learning
                      coding methodologies, Different projects using HTML, CSS &
                      Javscript, Real time projects, Concetual clarity,
                      Analytical skills
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Critical thinking, learning coding methodologies,
                      Different projects using advance Python
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}4`}>
                  <h1>Outcome</h1>
                  {gradeIndex === 0 && (
                    <span>
                      Student learns to code, plan and organize thoughts through
                      coding and is able to understand technology around them
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Logical thinking and create a foundation for structured
                      coding. Student will develop computational thinking,
                      problem solving skills, understanding of math & coding
                      techniques. This can lead to creation of entreprenuer
                      style market ready website. Can develop their own
                      portfolio or website
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Student learns to code, plan and organize thoughts through
                      coding and is able to understand technology around them.
                      This will build their logical thinking and create a
                      foundation for structured coding. Learn analytical skills
                      using web scrapping and learn market skills.
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}5`}>
                  <h1>Achievements</h1>
                  <img
                    className="pricing__card__achievement"
                    src={achievement2[gradeIndex]}
                    alt="achievement2"
                  />{" "}
                  <br />
                  {gradeIndex === 0 && (
                    <span>
                      Developer certificate, Lifetime community access
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Web developer Certificate Lifetime community access
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Python programming Certificate, Lifetime community access
                    </span>
                  )}
                </div>
              </div>
              <div
                className="pricing__card__box pricing__card__toggler"
                onClick={(event) => setMore2(!more2)}
              >
                {more2 ? "See less" : "See more"}
              </div>
              {/* <div className="pricing__card__price">
                <span className="discount__price" style={{color: color1}}>
                  &#8377; {formatPrice(discountPrice[1])}
                </span>
                <span className="actual__price">
                  &#8377; {formatPrice(price[1])}
                </span>
                <span className="discount__left__arrow"></span>
                <span className="discount__badge">
                  <span style={{fontWeight: "bold"}}>{off[1] + "%"}</span> off
                </span>
              </div>
              <div className="pricing__session__price">
                &#8377; {formatPrice(perSession[1])} per session
              </div> */}
            </div>
            <div
              className="download__currciculum"
              onClick={(event) =>
                onDownloadCurriculum(event, CurriculumGrade_4_7)
              }
            >
              <img src={DownloadIcon} alt="downloadIcon" /> Download Curriculum
            </div>
            <div
              className="buynow"
              onClick={redirectBuyNow}
              style={{
                background: selectedCardIndex === 1 ? color1 : "transparent",
                color: selectedCardIndex === 1 ? "white" : color1,
                borderColor: color1,
                borderBottomLeftRadius:
                  selectedCardIndex === 1 ? "5px" : "10px",
                borderBottomRightRadius:
                  selectedCardIndex === 1 ? "5px" : "10px",
              }}
            >
              Buy now
            </div>
          </div>
        </div>

        <div className="pricing__card">
          <img
            className="pricing__card__image"
            src={masterImage[gradeIndex]}
            alt="masterImage3"
          />
          <div
            className="pricing__card__content box-shadow"
            style={{
              borderColor: selectedCardIndex === 2 ? color2 : "transparent",
              outline: selectedCardIndex === 2 ? "3px" : "0px",
            }}
            onClick={(event) => setSelectedCardIndex(2)}
          >
            <div className="pricing__card__main">
              <div className="pricing__level" style={{background: color2}}>
                <h1>Master</h1>
                {classes[2]} Classes
              </div>
              <div className={`pricing__card__box box${gradeIndex}1`}>
                <h1>Build</h1>
                <img src={build3[gradeIndex][0]} alt="buildIcon30" />
                <img src={build3[gradeIndex][1]} alt="buildIcon31" /> <br />
                {gradeIndex === 0 && (
                  <span>
                    Microbit real-time projects, app development, create
                    animation, story making, making games
                  </span>
                )}
                {gradeIndex === 1 && (
                  <span>
                    Create advance level programs in python, Logic building,
                    Project based on real world data
                  </span>
                )}
                {gradeIndex === 2 && (
                  <span>
                    Game development, App development in python, Project based
                    on real world data
                  </span>
                )}
              </div>
              <div
                className="pricing__extra__boxes"
                style={{display: more3 ? "block" : "none"}}
              >
                <div className={`pricing__card__box box${gradeIndex}2`}>
                  <h1>Program Highlights</h1>
                  <span className="homapage__advance__bagde">Advance +</span>
                  {gradeIndex === 0 && (
                    <span>
                      Embedded system, coding methodologies, Web-based
                      simulation of physical device
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Conditionals, User interactivity, Logic building, decision
                      making, programming fundamentals, data structures, complex
                      problem solving, web scrapping
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Tkinter, pygame, Creative thinking, Conditionals, User
                      interactivity, functions, coding methodologies
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}3`}>
                  <h1>Benefits</h1>
                  {gradeIndex === 0 && (
                    <span>
                      Realtime projects, App development, Game development,
                      Conceptual clarity, Analytical skills
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Critical thinking, learning coding methodologies,
                      Different projects using advance Python
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Realtime projects, App development, Game development,
                      Conceptual clarity, Analytical skills
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}4`}>
                  <h1>Outcome</h1>
                  {gradeIndex === 0 && (
                    <span>
                      Student will develop computational thinking, problem
                      solving skills, deepen your understanding of maths &
                      coding techniques. This can lead to creation of
                      enterprenuers style marketready apps
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Student learn to plan and organize thoughts through coding
                      and is able to understand technology around them. This
                      will build their logical thinking and create a foundation
                      for structured coding. Learn analytical skills using web
                      scrapping and learn market skills
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Student will develop computational thinking, problem
                      solving skills, deepen your understanding of maths &
                      coding techniques. This can lead to creation of
                      entreprenuer style market ready apps
                    </span>
                  )}
                </div>
                <div className={`pricing__card__box box${gradeIndex}5`}>
                  <h1>Achievements</h1>
                  <img
                    className="pricing__card__achievement"
                    src={Achievement31}
                    alt="achievement31"
                  />
                  <img
                    className="pricing__card__achievement"
                    src={Achievement32}
                    alt="achievement32"
                  />{" "}
                  <br />
                  {gradeIndex === 0 && (
                    <span>
                      Pro Coder certificate, Lifetime community access,
                      Counselling from Google & IIT Alumni, Opportunity to visit
                      Silicon Valley
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Python Programmer Certificate, Lifetime community access,
                      Counselling from Google & IIT Alumni, Opportunity to visit
                      Silicon Valley
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      App Developer Certificate, Lifetime community access,
                      Counselling from Google & IIT Alumni, Opportunity to visit
                      Silicon Valley
                    </span>
                  )}
                </div>
              </div>
              <div
                className="pricing__card__box pricing__card__toggler"
                onClick={(event) => setMore3(!more3)}
              >
                {more3 ? "See less" : "See more"}
              </div>
              {/* <div className="pricing__card__price">
                <span className="discount__price" style={{color: color2}}>
                  &#8377; {formatPrice(discountPrice[2])}
                </span>
                <span className="actual__price">
                  &#8377; {formatPrice(price[2])}
                </span>
                <span className="discount__left__arrow"></span>
                <span className="discount__badge">
                  <span style={{fontWeight: "bold"}}>{off[2] + "%"}</span> off
                </span>
              </div>
              <div className="pricing__session__price">
                &#8377; {formatPrice(perSession[2])} per session
              </div> */}
            </div>
            <div
              className="download__currciculum"
              onClick={(event) =>
                onDownloadCurriculum(event, CurriculumGrade_8_12)
              }
            >
              <img src={DownloadIcon} alt="downloadIcon" /> Download Curriculum
            </div>
            <div
              className="buynow"
              onClick={redirectBuyNow}
              style={{
                background: selectedCardIndex === 2 ? color2 : "transparent",
                color: selectedCardIndex === 2 ? "white" : color2,
                borderColor: color2,
                borderBottomLeftRadius:
                  selectedCardIndex === 2 ? "5px" : "10px",
                borderBottomRightRadius:
                  selectedCardIndex === 2 ? "5px" : "10px",
              }}
            >
              Buy now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
