import React from "react";
import {Route, Redirect} from "react-router-dom";
import {isAuthenticated} from "../../Pages/Login/LoginAPICalls";
import { getMailTokenFromUrl } from "../Utils/Helper";

export default function PrivateRoute({component: Component, ...rest}) {

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() || getMailTokenFromUrl().token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
}
