import React, { useEffect, useState, useContext } from "react";
import Base from "../../../Common/Components/Base/Base";
import VideoCard from "./VideoCard";
import BackNavigation from "../../../Common/Components/BackNavigation";
import { getFreeCourses, updateFreeLecture } from "./CoursesAPIcalls";
import Overlay from "../../../Common/Components/Overlay";
import { UserContext } from "../../../App";
const FreeCoursePlay = () => {
  const { userData, token, setProfileInfo, freecourse } = useContext(
    UserContext
  );
  const [freeVideo, setFreeVideo] = useState([]);
  const [loading, setloading] = useState(false);
  const [currIndex, setcurrIndex] = useState(freecourse);
  useEffect(() => {
    if (freecourse != undefined) {
     
      setcurrIndex(freecourse);
    }
  }, [freecourse]);
  useEffect(() => {
    setloading(true);
  
    getFreeCourses().then((data) => {
      setFreeVideo(data);
      setloading(false);
    });
  }, []);

  useEffect(async () => {
    if (currIndex != null || currIndex != undefined) {
      await updateFreeLecture(currIndex, token).then(async (res) => {
        console.log("response after update", res);
        await setProfileInfo((prev) => ({ ...prev, freeCourse: res.newcount }));
        //console.log("user data afer update", userData);
      });
    }
  }, [currIndex]);

  const checkLock = (index) => {
    return index > currIndex;
  };

  return (
    <Base>
      <div className="w_85 mx-auto">
        <BackNavigation />
        <div className=" d-flex justify-content-center align-items-center flex-wrap">
          {loading && <Overlay />}
          {freeVideo.map((item, index) => {
            
            return (
              <VideoCard
                key={item._id}
                data={item}
                index={index}
                _class={checkLock(index) ? "locked_video" : null}
                currIndex={() => setcurrIndex(currIndex + 1)}
              />
            );
          })}
        </div>
      </div>
    </Base>
  );
};

export default FreeCoursePlay;
