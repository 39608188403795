import React from 'react'
import './Component.css'
import TkButton from '../../../Common/Components/TkButton'

const Message = ({
  titleImg,
  illustration,
  subtitle,
  content,
  btnValue,
  handleBtnClick,
  setValues
}) => {
  return (
    <div className='message_container'>
      <div>
        <img src={titleImg} alt={titleImg} className='message_img' />
        <p style={{ marginBottom: '1rem' }}>{subtitle}</p>
        <p>{content}</p>
        <TkButton
          name={btnValue}
          _class='message_feedback_btn'
          _onBtnClick={handleBtnClick}
        ></TkButton>
      </div>
      <div>
        <img
          src={illustration}
          alt='color paper blast'
          className='message_paper_blast_img'
        />
      </div>
    </div>
  )
}

export default Message
