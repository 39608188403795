import React, {useState} from "react";
import {ImCross} from "react-icons/im";
const BookSlotModal = ({showModal, closeModal}) => {
  return (
    <div className={`modal bookslot_modal ${showModal && "show_modal"}`}>
      <div className="modal_content theme_bg_color">
        <ImCross size={15} onClick={() => closeModal()} />
        <div className=" text-white d-flex align-items-center flex-column">
          <h1>
            {" "}
            <b> Book your slot</b>
          </h1>
          <h4 className="mt-4">Reach us at</h4>
          <div className="d-flex ">
            <span>+91 2653561742</span>
          </div>
          <span className="">hello@techokids.com</span>
        </div>
      </div>
    </div>
  );
};

export default BookSlotModal;
