import React, { useContext } from "react";
import "./commonComponents.css";

const TkButton = ({
  name,

  _onBtnClick,
  _type,
  _class,
  style,
  Licon,
  Ricon,
}) => {
  return (
    <button
      type={_type ? _type : "button"}
      className={`tk_btn ${_class}`}
      style={style}
      onClick={(e) => (_onBtnClick ? _onBtnClick(e) : e.preventDefault())}
    >
      {Licon}
      <span>{name}</span>
      {Ricon}
    </button>
  );
};

export default TkButton;
