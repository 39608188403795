import React, { useState } from "react";
import "./Component.css";
import TkButton from "../../../Common/Components/TkButton";
import CloseIcon from "@material-ui/icons/Close";
import DateComp from "./RescheduleDate";
import { rescheduleTrial } from "../DashboardAPICalls";

const Reschedule = ({
  rescheduleValues,
  setRescheduleValues,
  setIsRescheduleBtnClicked,
  slotID,
  statusModal,
  setStatusModal,
}) => {
  const handleTimeClick = (event) => {
    setRescheduleValues((prevValues) => ({
      ...prevValues,
      time: event.target.value,
    }));
  };
  const checkClicked = (clickedTime) => {
    if (rescheduleValues.time == clickedTime) {
      return "reschedule_time_container_selected";
    } else {
      return "reschedule_time_container";
    }
  };
  const handleSubmit = async () => {
    if (rescheduleValues.date == "" || rescheduleValues.time == "") {
      setStatusModal({
        ...statusModal,
        status: "error",
        showStatusModal: true,
        statusText: "Select Day and Time",
      });

      return;
    }
    await rescheduleTrial(slotID, rescheduleValues).then((result) => {
      if (result.error) {
        setStatusModal({
          ...statusModal,
          status: "error",
          showStatusModal: true,
          statusText: result.error,
        });
        return;
      } else {
        setStatusModal({
          ...statusModal,
          status: "success",
          showStatusModal: true,
          statusText: result.message,
        });
        window.location.reload();
        setIsRescheduleBtnClicked(false);
      }
    });
  };
  return (
    <div className="reschedule_container">
      <span
        className="reschedule_close_icon"
        onClick={() => setIsRescheduleBtnClicked(false)}
      >
        <CloseIcon fontSize="large"></CloseIcon>
      </span>
      <h1>Book your free trial class</h1>
      <div className="reschedule_select_date">
        <p>Select Date</p>
        <DateComp
          offset={1}
          setRescheduleValues={setRescheduleValues}
          rescheduleValues={rescheduleValues}
        ></DateComp>
        <DateComp
          offset={2}
          setRescheduleValues={setRescheduleValues}
          rescheduleValues={rescheduleValues}
        ></DateComp>
        <DateComp
          offset={3}
          setRescheduleValues={setRescheduleValues}
          rescheduleValues={rescheduleValues}
        ></DateComp>
        <DateComp
          offset={4}
          setRescheduleValues={setRescheduleValues}
          rescheduleValues={rescheduleValues}
        ></DateComp>
      </div>
      <div className="reschedule_select_time">
        <p>Select Time</p>
        <button
          className={`reschedule_time_container ${checkClicked("10:00 AM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="10:00 AM"
        >
          10:00 AM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("11:00 AM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="11:00 AM"
        >
          11:00 AM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("12:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="12:00 PM"
        >
          12:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("01:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="01:00 PM"
        >
          01:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("02:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="02:00 PM"
        >
          02:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("03:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="03:00 PM"
        >
          03:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("04:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="04:00 PM"
        >
          04:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("05:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="05:00 PM"
        >
          05:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("06:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="06:00 PM"
        >
          06:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("07:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="07:00 PM"
        >
          07:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("08:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="08:00 PM"
        >
          08:00 PM
        </button>
        <button
          className={`reschedule_time_container ${checkClicked("09:00 PM")}`}
          onClick={(event) => handleTimeClick(event)}
          value="09:00 PM"
        >
          09:00 PM
        </button>
      </div>
      <span onClick={handleSubmit}>
        <TkButton _class="reschedule_submit_btn" name="Submit"></TkButton>
      </span>
    </div>
  );
};

export default Reschedule;
