export const openInNewTab = (url) => {
  window.open(url, "_blank");
};
export const onSameTab = (url) => {
  window.location.href = url;
};


export const googleUrl = (dates,time) => {
  let T = ""
  if (time != null && dates != null) {

    for (var i = 0; i < time.length; i++) {
      if (time[i] == "A" || time[i] == "P") {
        T += " ";
        T += time[i];
      } else {
        T += time[i];
      }
    }
  }
  // dates=[start-custom format='Ymd\\THi00\\Z']/[end-custom format='Ymd\\THi00\\Z']
  let googleDate = new Date(dates + " " + T)
  let eventTitle = "Techokids Class";
  let eventDesc = "Trail Class Desc Here";
  // let date = "20200822/T113000/Z/20200822/T213000/Z";
  let calendarUrl;
  calendarUrl = `https://calendar.google.com/calendar/r/eventedit?text=${eventTitle}&details=${eventDesc}&dates=${googleDate}`;

  return calendarUrl;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getMailTokenFromUrl = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let token = urlParams.get("token");
  let email = urlParams.get("email");
  let localData;
  if (token) {
    localData = {email: email, token: token};
    localStorage.setItem("tk_jwt", JSON.stringify(localData));
  } else {
    localData = false;
  }

  return localData;
};
export const validatePassword = (password) => {
  // Minimum eight characters, at least one letter and one number:
  const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  return re.test(String(password));
};

export const checkBase64 = (base64Img) => {
  const re = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;
  return re.test(base64Img);
};

export const _arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const calculateAge = (dobData) => {
  let dob = new Date(dobData);
  if (dob != undefined) {
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    return age;
  }
};
export const convertDate = (date) => {
  
  var parts = date.split("/");
  // year, month, day
  var mydate = new Date(parts[2], parts[0] - 1, parts[1]);
  let convertedDate = new Date(date).toString();
  return convertedDate.substring(0, 15);
};


// export const scheduleTimer = () => {



export const getTimeRemaining = (endtime) =>{

  let total = Date.parse(endtime) - Date.parse(new Date());
  let hours = Math.floor(total / (1000 * 60 * 60));
  let minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((total % (1000 * 60)) / 1000);

  if (hours < 10) hours = '0'+ hours;
  if (minutes < 10) minutes = '0'+ minutes;
  if (seconds < 10) seconds = '0'+ seconds;

  return {
    total,
    hours,
    minutes,
    seconds
  };
}