import React from "react";
import "./Component.css";

const UpcomingClassItem = ({ classNo, desc, content }) => {
  return (
    <div className="upcoming_item d-flex flex-column justify-content-between">
      <h4>
        <b>{classNo}</b>
      </h4>
      <p>{desc}</p>
      {content}
    </div>
  );
};

export default UpcomingClassItem;
