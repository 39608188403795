import { base_route } from "../../backend";

export const setPassword = (password, token) => {
  return fetch(`${base_route}/register/setpassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({ password }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const rescheduleTrial = (slotID, rescheduleValues) => {
  return fetch(`${base_route}/slot/trialreschedule`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ slotID, rescheduleValues }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const addFeedback = (slotID, feedbackValues) => {
  return fetch(`${base_route}/slot/addstudentfeedback`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ slotID, feedbackValues }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getFreeSlot = (token) => {
  return fetch(`${base_route}/booking/teacherLink`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getSchedule = (token) => {
  return fetch(`${base_route}/booking/myfreeSchedule`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getCurriculum = (level, _package) => {
  console.log("level", level, _package);

  return fetch(`${base_route}/curriculum/level/${level}/package/${_package}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getClasses = (token) => {
  return fetch(`${base_route}/student/paidclasses`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getClassesuncoming = (token) => {
  return fetch(`${base_route}/student/paidclasses/upcoming`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
