import React from 'react'
import { useLocation } from 'react-router'
import BackNavigation from '../../Common/Components/BackNavigation'
import Base from '../../Common/Components/Base/Base'
import TkButton from '../../Common/Components/TkButton'
import { base_route } from '../../backend'
import { openInNewTab } from '../../Common/Utils/Helper'

const ViewProject = () => {
  const location = useLocation()
  const projectData = location.state.projectData
  let imgsrc = ''
  if (projectData.coverimage === '/static/media/boye.850b8e56.png') {
    imgsrc = '/static/media/boye.850b8e56.png'
  } else if (projectData.coverimage[0] === 'b') {
    imgsrc = projectData.coverimage
  } else {
    imgsrc = base_route + '/' + projectData.coverimage
  }
  return (
    <Base>
      <BackNavigation />
      <div className='project_view_container'>
        <div className='project_view_img_container'>
          <img src={imgsrc} alt='' />
        </div>
        <div className='project_view_info'>
          <h1>{projectData.title}</h1>
          <p className='project_view_category_label'>Catergory</p>
          <span className='project_view_category'>{projectData.category}</span>
          <p className='project_view_description'>{projectData.description}</p>
          <TkButton
            name={'Project Link'}
            _class='project_link_btn'
            _onBtnClick={() => openInNewTab(projectData.projectlink)}
          />
        </div>
      </div>
    </Base>
  )
}

export default ViewProject
