import React, { useState } from 'react'
import { ImCross } from 'react-icons/im'
import TkButton from '../../../Common/Components/TkButton'
import { setPassword } from '../DashboardAPICalls'
import './Component.css'
import { validatePassword } from '../../../Common/Utils/Helper'

const PasswordModal = ({
  showModal,
  closeModal,
  email,
  token,
  passwordStatusModal,
  setPassworStatusModal,
  setIsSetPasswordLater,
  isSetPasswordLater
}) => {
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    error: false,
    success: false,
  })

  const { password, confirmPassword, error, success } = values

  const handleOnChange = (field) => (event) => {
    setValues({ ...values, [field]: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!validatePassword(password)) {
      setValues({
        ...values,
        error:
          'Password should have minimum eight characters, at least one letter and one number',
      })
    } else if (password != confirmPassword) {
      setValues({ ...values, error: "Password don't match" })
    } else {
      setValues({ ...values, error: false })
      setPassword(password, token).then((data) => {
        if (data) {
          setPassworStatusModal({
            ...passwordStatusModal,
            showStatusModal: true,
            status: 'success',
            statusText: 'Password Created',
          })
          closeModal()
          // isSetUpLaterClicked(true)
        }
      })
    }
  }
  return (
    <div className={`modal password   ${showModal && 'show_modal'}`}>
      <div className='modal_content'>
        <ImCross size={15} onClick={() => closeModal()} />
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <>
            <h5>Create your unique password</h5>
            <form className='password_modal_form'>
              <div className='form-group password_modal'>
                <label>Email</label>
                <input
                  type='text'
                  placeholder='email'
                  className='email'
                  value={email}
                  readOnly
                />
              </div>
              <div className='form-group password_modal'>
                <label>Password</label>
                <input
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={handleOnChange('password')}
                />
              </div>
              <div className='form-group password_modal'>
                <label>Confirm Password</label>
                <input
                  type='password'
                  placeholder='Confirm Password'
                  value={confirmPassword}
                  onChange={handleOnChange('confirmPassword')}
                />
              </div>
              <h5 className='text-center text-danger error'>{error}</h5>

              <div className="d-flex justify-content-between align-items-center">
                <TkButton
                  name={'SUBMIT'}
                  style={{ backgroundColor: '#60ba1d', fontWeight:'900' }}
                  _class='mt-5'
                  _type='submit'
                  _onBtnClick={(e) => handleSubmit(e)}
                />
                <TkButton
                  name={'LATER'}
                  style={{ backgroundColor: '#00b4db', fontWeight:'900' }}
                  _class='mt-5'
                  _type='button'
                  _onBtnClick={() => setIsSetPasswordLater(true)}
                />
              </div>
            </form>
            <h5>{error}</h5>
          </>
        </div>
      </div>
    </div>
  )
}

export default PasswordModal
