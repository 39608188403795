import React from "react";
import "../Dashboard.css";
import robot from "../../../Assets/Dashboard/robot.png";

const WelcomeMsg = ({ _class, name, payment }) => {
  const welcome = () => {
    var _name = name;
    if (name) {
      const words = name.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      _name = words.join(" ");
    } else {
      _name = "";
    }

    if (payment) {
      return (
        <div
          className={`ds_welcome  ${_class} d-flex justify-content-between align-items-center`}
        >
          <div className="welcome_msg ">
            <h4>
              <b>Welcome Back, {_name}!</b>
            </h4>
            <p>
              With your dashboard access, you can learn, track and create
              projects. It also provides you with opportunities to explore your
              creativity, plan your classes and learn with fun. Start your
              coding classes and connect with your assigned teacher to kickstart
              your learning.
            </p>
          </div>
          <img src={robot} alt="welcome" className="welcome_img img-fluid" />
        </div>
      );
    } else {
      return (
        <div
          className={`ds_welcome  ${_class} d-flex justify-content-between align-items-center`}
        >
          <div className="welcome_msg ">
            <h4>
              <b>Welcome {_name}</b>
            </h4>
            <p>
              We are glad that you chose TechoKids for your online live coding
              classes. This dashboard provides you many opportunities to explore
              and learn with us. Checkout our freemium package to kickstart your
              learning.
            </p>
          </div>
          <img src={robot} alt="welcome" className="welcome_img img-fluid" />
        </div>
      );
    }
  };

  return <>{welcome()}</>;
};

export default WelcomeMsg;
