import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './Common/Components/PrivateRoute'
import FreeCoursePlay from './Pages/Courses/Components/FreeCoursePlay'
import Courses from './Pages/Courses/Courses'
import Dashboard from './Pages/Dashboard/Dashboard'
import InviteWin from './Pages/InviteWin/InviteWin'
import { getProfile, isAuthenticated } from './Pages/Login/LoginAPICalls'
import Login from './Pages/Login/Login'
import EditProfile from './Pages/Profile/EditProfile'
import Profile from './Pages/Profile/Profile'
import Project from './Pages/Project/Project'
import ProjectUpload from './Pages/Project/ProjectUpload'
import ChangePassword from './Pages/Setting/Routes/ChangePassword'
import ContactUs from './Pages/Setting/Routes/ContactUs'
import Subscription from './Pages/Setting/Routes/Subscription/Subscription'
import TicketCreate from './Pages/Setting/Routes/Ticket/TicketCreate'
import TicketSupport from './Pages/Setting/Routes/Ticket/TicketSupport'
import Setting from './Pages/Setting/Settings'
import TechSupport from './Pages/TechSupport/TechSupport'
import Overlay from './Common/Components/Overlay'
import MyReport from './Pages/MyReport/MyReport'
import LoginWithOTP from './Pages/Login/LoginWithOTP'
import ResetPassword from './Pages/Login/ResetPassword'
import { getMailTokenFromUrl } from './Common/Utils/Helper'
import ProjectView from './Pages/Project/ProjectView'

export const UserContext = React.createContext()

const App = () => {
  const [profileInfo, setProfileInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [isSetPasswordLater, setIsSetPasswordLater] = useState(false)

  // const { email, token } = isAuthenticated();

  let token, email
  if (getMailTokenFromUrl().token) {
    token = getMailTokenFromUrl().token
    email = getMailTokenFromUrl().email
  } else {
    token = isAuthenticated().token
    email = isAuthenticated().email
  }
  useEffect(() => {
    if (token != undefined) {
      setLoading(true)
      getUserProfile().then((data) => {
        setProfileInfo(data)
      })
    }
  }, [])

  const getUserProfile = () => {
    return getProfile(token)
      .then((response) => {
        return response
      })
      .catch((err) => {
        alert(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <UserContext.Provider
      value={
        {
          userData: profileInfo,
          loading: loading,
          setLoading,
          setProfileInfo,
          payment: profileInfo.payment,
          freecourse: profileInfo.freeCourse,
          token: token,
          isSetPasswordLater,
          setIsSetPasswordLater
        }
        // [isSetPasswordLater, setIsSetPasswordLater])
      }
    >
      <Router>
        {loading && <Overlay />}

        <Switch>
          <Route path='/login' exact component={Login} />
          <Route path='/login-with-otp' exact component={LoginWithOTP} />
          <Route path='/reset-password' exact component={ResetPassword} />
          <PrivateRoute path='/' exact component={Dashboard} />
          <PrivateRoute path='/dashboard' exact component={Dashboard} />
          <PrivateRoute path='/support' exact component={TechSupport} />
          <PrivateRoute path='/referral' exact component={InviteWin} />
          <PrivateRoute path='/courses' exact component={Courses} />
          <PrivateRoute path='/courses/free' exact component={FreeCoursePlay} />
          <PrivateRoute path='/profile' exact component={Profile} />
          <PrivateRoute path='/profile/edit' exact component={EditProfile} />
          <PrivateRoute path='/project' exact component={Project} />
          <PrivateRoute
            path='/project/upload'
            exact
            component={ProjectUpload}
          />
          <PrivateRoute path='/project/view' exact component={ProjectView} />
          <PrivateRoute
            path='/setting/support/ticket'
            exact
            component={TicketSupport}
          />
          <PrivateRoute path='/setting' exact component={Setting} />
          <PrivateRoute
            path='/setting/subscription'
            exact
            component={Subscription}
          />
          <PrivateRoute
            path='/setting/create/ticket'
            exact
            component={TicketCreate}
          />
          <PrivateRoute path='/setting/contactus' exact component={ContactUs} />
          <PrivateRoute
            path='/setting/change/password'
            exact
            component={ChangePassword}
          />
          <PrivateRoute path='/report' exact component={MyReport} />
        </Switch>
      </Router>
    </UserContext.Provider>
  )
}

export default App
