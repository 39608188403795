import React from "react";
import Base from "../../Common/Components/Base/Base";
import SettingItem from "./Components/SettingItem";
import "./Setting.css";
import {SettingsData} from "./DataSettings";
import {ReactComponent as Logout} from "../../Assets/Setting/logout.svg";
import fbImg from "../../Assets/Social/fb.png";
import instaImg from "../../Assets/Social/insta.png";
import linkedinImg from "../../Assets/Social/linkedin.png";
import twittImg from "../../Assets/Social/twitt.png";
import ytImg from "../../Assets/Social/yt.png";
import {logoutHandler} from "./SettingsApiCalls";


export const openInNewTab = (url) => {
  window.open(url, "_blank");
};

const Setting = () => {

  return (
    <Base>
      <div className="setting_section  w_85 mx-auto">
        <ul>
          {SettingsData.map((item, index) => {
            return (
              <li>
                <SettingItem item={item} />
              </li>
            );
          })}
          <li>
            <button
              className="logout_btn"
              onClick={() =>
                logoutHandler(() =>
                  window.open("https://techokids.com", "_self")
                )
              }
            >
              <span className="logout_icon">
                <Logout color="white" />
              </span>
              <span>Logout</span>
            </button>
          </li>
        </ul>
        <span>
          <b> Follow Us On</b>
        </span>
        <div className="setting_social_icon mt-3">
          <img
            src={fbImg}
            alt="Facebook"
            onClick={() => openInNewTab("https://www.facebook.com/TechoKids/")}
          />

          <img
            src={instaImg}
            alt="Instagram"
            onClick={() => openInNewTab("https://www.instagram.com/techokids/")}
          />
          <img
            src={linkedinImg}
            alt="Linkedin"
            onClick={() =>
              openInNewTab("https://www.linkedin.com/company/techokids/")
            }
          />
          <img
            src={twittImg}
            alt="Twitter"
            onClick={() => openInNewTab("https://twitter.com/TechoKids/")}
          />
          <img
            src={ytImg}
            alt="Youtube"
            onClick={() =>
              openInNewTab(
                "https://www.youtube.com/channel/UCIQ3U-ea7I3oOA6a8dnvIcQ/"
              )
            }
          />
        </div>
      </div>
    </Base>
  );
};

export default Setting;
