import React from "react";
import "../MyReport.css";

const SkillsUnlocked = ({name, unlocked}) => {
  return (
    <div className="skill_unlocked_item d-flex">
      <div className="col-5 col-md-3">
        <div className="skill_name ">{name}</div>
      </div>
      <div className="skills_progress d-flex align-items-center justify-content-between col-md-8 col-7">
        {[...Array(unlocked)].map((i) => (
          <div className="progress_dot active" key={i}></div>
        ))}
        {[...Array(10 - unlocked)].map((i) => (
          <div className="progress_dot" key={i}></div>
        ))}
      </div>
    </div>
  );
};

export default SkillsUnlocked;
