import { base_route } from "../../../backend";

export const getFreeCourses = () => {
  return fetch(`${base_route}/curriculum/getFreeLectures`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getCurriculum = (level, _package) => {
  console.log("level", level, _package);

  return fetch(`${base_route}/curriculum/level/${level}/package/Master`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getClasses = (token) => {
  return fetch(`${base_route}/student/paidclasses`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const updateFreeLecture = (currIndex, token) => {
  return fetch(`${base_route}/curriculum/updateFreeLecture`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({ currIndex }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
