import React from "react";
import NoSubscription from "../../../../Assets/Setting/NoSubscription.png";
import BackNavigation from "../../../../Common/Components/BackNavigation";
import "./Subscription.css";

const FreeSubscription = () => {
  return (
    <div className="w_85 mx-auto ">
      <BackNavigation />

      <div className="no_subscription d-flex align-items-center flex-column">
        <img src={NoSubscription} alt="no subscription" className="img-fluid" />
        <h4 className="mt-5 text-center">
          You don't have any subcription at the moment
        </h4>
      </div>
    </div>
  );
};

export default FreeSubscription;
