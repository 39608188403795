import React, {useContext} from "react";
import {UserContext} from "../../../../App";
import Base from "../../../../Common/Components/Base/Base";
import FreeSubscription from "./FreeSubscription";
import PaidSubscription from "./PaidSubscription";

const Subscription = () => {
  const UserInfo = useContext(UserContext);

  return (
    <Base>
      {UserInfo.payment ? <PaidSubscription /> : <FreeSubscription />}
    </Base>
  );
};

export default Subscription;
