import React from "react";
import "./commonComponents.css";
import "../../Assets/Dashboard/tower.png";

const TkSection = ({text, content, Btn, img, className}) => {
  return (
    <div className="tk_section d-flex justify-content-between">
      <div className="tk_section_content theme_color d-flex flex-column justify-content-between ">
        {text}
        {content}
        {Btn}
      </div>
      <img src={img} alt="icon" className={`${className} img-fluid`} />
    </div>
  );
};

export default TkSection;
