import React, { useState } from "react";
import testNetimg from "../../../Assets/TechSupport/Radio.gif";
import testAudioimg from "../../../Assets/TechSupport/audio.gif";
import TkButton from "../../../Common/Components/TkButton";
import Webcam from "react-webcam";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { IoMdCall } from "react-icons/io";

const CheckPcModal = ({ showModal, closeModal }) => {
  const [internet, setInternet] = useState(false);
  const [video, setVideo] = useState(false);
  const [voice, setVoice] = useState(false);
  const [testNo, settestNo] = useState(0);
  const [showCam, setshowCam] = useState(false);
  const [error, setError] = useState(false);

  const { transcript, resetTranscript } = useSpeechRecognition();

  const onCloseModal = () => {
    setVideo(false);
    setVoice(false);
    settestNo(0);
    setInternet(false);
    setshowCam(false);
    setError(false);
    closeModal();
  };
  const onWorking = (type) => {
    settestNo(testNo + 1);
    if (type === "net") {
      setInternet(true);
      setshowCam(true);
    } else if (type === "audio") {
      setVoice(true);
      SpeechRecognition.abortListening();
    } else if (type === "cam") {
      setVideo(true);
      setshowCam(false);
    } else if (type == "noaudio") {
      setVoice(false);
      SpeechRecognition.abortListening();
    }
  };

  const onNotWorking = (type) => {
    if (type === "net") {
      settestNo(testNo + 1);

      setInternet(false);
    } else if (type === "audio") {
      settestNo(testNo + 1);

      setVoice(false);
    } else if (type === "cam") {
      setError(true);
      setVideo(false);
      setshowCam(false);
    }
  };

  const testNetView = () => {
    setTimeout(function () {
      let online;
      online = window.navigator.onLine;
      online ? onWorking("net") : onNotWorking("net");
    }, 9000);

    return (
      <div className=" text-white d-flex flex-column align-items-center justify-content-center text-center">
        <h1 className=" font-weight-bold">Internet Test</h1>
        <h4 className=" mt-3 ">
          We are testing your internet connect, it may take 10-15 sec
        </h4>
        <img src={testNetimg} alt="net test" className="net_gif mt-3" />
      </div>
    );
  };

  const testCamView = () => {
    return (
      <div className=" text-white d-flex flex-column align-items-center justify-content-center text-center">
        {error ? (
          errorView()
        ) : (
          <>
            <Webcam
              audio={false}
              videoConstraints={showCam}
              height={250}
              width={300}
            />
            <h4 className="mt-3 ">Are you able to see your face?</h4>

            <div className="d-flex">
              <TkButton
                name={"Yes"}
                style={{ backgroundColor: "#00B23B" }}
                _class="mr-4"
                _onBtnClick={() => onWorking("cam")}
              />
              <TkButton
                name={"No"}
                style={{ backgroundColor: "#00B23B" }}
                _onBtnClick={() => onNotWorking("cam")}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const testAudioView = () => {
    SpeechRecognition.startListening();
    // console.log("Hello", transcript);
    if (transcript === "hello") {
      onWorking("audio");
    }
    if (showModal) {
      setTimeout(function () {
        onWorking("noaudio");
      }, 7000);
    }

    return (
      <div className=" text-white d-flex flex-column align-items-center justify-content-center text-center">
        <h1 className=" font-weight-bold">Say HELLO !</h1>

        <img src={testAudioimg} alt="net test" className="audio_test mt-3" />
      </div>
    );
  };
  const errorView = () => {
    return (
      <div className=" text-white d-flex flex-column align-items-center justify-content-center text-center">
        <h6 className="mt-3 ">
          If it doesn't work, kindly call us to help you
        </h6>
        <div className="d-flex mt-4 ">
          <IoMdCall />
          <h4 className="mr-3 ">+91-8896518896</h4>
        </div>
        <TkButton
          name={"OK"}
          style={{ backgroundColor: "#00B23B" }}
          _class="mt-4"
          _onBtnClick={() => onCloseModal()}
        />
      </div>
    );
  };
  const testDoneView = () => {
    return (
      <div className=" text-white d-flex flex-column align-items-center justify-content-center text-center">
        <h1 className=" font-weight-bold">Test Completed</h1>
        <div className="d-flex flex-column mt-4 ">
          <div className="d-flex ">
            <input
              className="form-check-input"
              type="checkbox"
              checked={internet}
            />
            <label className=" form-check-label">Internet</label>
          </div>
          <div className="d-flex ">
            <input
              className="form-check-input"
              type="checkbox"
              checked={video}
            />
            <label className="form-check-label">Camera</label>
          </div>
          <div className="d-flex ">
            <input
              className="form-check-input"
              type="checkbox"
              checked={voice}
            />
            <label className="form-check-label">Audio</label>
          </div>
          <TkButton
            name={"Ok"}
            style={{ backgroundColor: "#00B23B" }}
            _class="mt-5"
            _onBtnClick={() => closeModal()}
          />
        </div>
      </div>
    );
  };

  let view;
  const testViews = () => {
    switch (testNo) {
      case 0:
        view = testNetView();
        break;
      case 1:
        view = testCamView();
        break;
      case 2:
        view = testAudioView();
        break;
      case 3:
        view = testDoneView();
        break;
      default:
        testNetView();
        break;
    }
    return view;
  };
  return (
    <div className={`modal check_modal ${showModal && "show_modal"}`}>
      <div className="modal_content theme_bg_color">
        {showModal && testViews()}
      </div>
    </div>
  );
};

export default CheckPcModal;
