import React from "react";
import "./component.css";

export const InputFeild = ({type, placeholder,value, onChange}) => {
  return (
    <div className="form-group login_page_form_group">
      <input
        type={type}
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
