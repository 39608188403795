import React, { useContext, useEffect, useRef, useState } from "react";
import Base from "../../Common/Components/Base/Base";
import "./Dashboard.css";
import ProfileImgCover from "../../Common/Components/ProfileImgCover";
import Trainee from "../../Assets/Badge/Trainee.png";
import badge1 from "../../Assets/Badge/Artboard 1.png";
import badge2 from "../../Assets/Badge/Artboard 2.png";
import badge3 from "../../Assets/Badge/Artboard 3.png";
import badge4 from "../../Assets/Badge/Artboard 4.png";
import badge5 from "../../Assets/Badge/Artboard 5.png";
import badge6 from "../../Assets/Badge/Artboard 6.png";
import badge7 from "../../Assets/Badge/Artboard 7.png";
import badge8 from "../../Assets/Badge/Artboard 8.png";
import badge9 from "../../Assets/Badge/Artboard 9.png";
import badge10 from "../../Assets/Badge/Artboard 10.png";
import badge11 from "../../Assets/Badge/Artboard 11.png";
import badge12 from "../../Assets/Badge/Artboard 12.png";
import badge13 from "../../Assets/Badge/Artboard 13.png";
import badge14 from "../../Assets/Badge/Artboard 14.png";
import badge15 from "../../Assets/Badge/Artboard 15.png";
import badge1Locked from "../../Assets/Badge/Artboard 1 copy.png";
import badge2Locked from "../../Assets/Badge/Artboard 2 copy.png";
import badge3Locked from "../../Assets/Badge/Artboard 3 copy.png";
import badge4Locked from "../../Assets/Badge/Artboard 4 copy.png";
import badge5Locked from "../../Assets/Badge/Artboard 5 copy.png";
import badge6Locked from "../../Assets/Badge/Artboard 6 copy.png";
import badge7Locked from "../../Assets/Badge/Artboard 7 copy.png";
import badge8Locked from "../../Assets/Badge/Artboard 8 copy.png";
import badge9Locked from "../../Assets/Badge/Artboard 9 copy.png";
import badge10Locked from "../../Assets/Badge/Artboard 10 copy.png";
import badge11Locked from "../../Assets/Badge/Artboard 11 copy.png";
import badge12Locked from "../../Assets/Badge/Artboard 12 copy.png";
import badge13Locked from "../../Assets/Badge/Artboard 13 copy.png";
import badge14Locked from "../../Assets/Badge/Artboard 14 copy.png";
import badge15Locked from "../../Assets/Badge/Artboard 15 copy.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import roadmap from "../../Assets/Dashboard/roadmap.png";
import nextBtn from "../../Assets/Dashboard/nextbtn.png";
import prevBtn from "../../Assets/Dashboard/prevbtn.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UpcomingClassItem from "./Components/UpcomingClassItem";
import TkButton from "../../Common/Components/TkButton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountDownTimer from "./Components/CountDownTimer";
import WelcomeMsg from "./Components/WelcomeMsg";
import { UserContext } from "../../App";
import {
  getClassesuncoming,
  getClasses,
  getCurriculum,
} from "./DashboardAPICalls";

const PaidDashboard = () => {
  const userInfo = useContext(UserContext);
  const ref = useRef(null);

  const scrollRight = () => {
    ref.current.scrollLeft += 1000;
  };
  const scrollLeft = () => {
    ref.current.scrollLeft -= 1000;
  };

  const [uncompleted, setUncompleted] = useState(0);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [classes, setClasses] = useState([]);
  const [classes2, setClasses2] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  useEffect(async () => {
    var level = userInfo.userData.level[6];
    var _package = userInfo.userData.package;
    await getCurriculum(level, _package).then((res) => {
      console.log("curriculum", res);
      setCurriculum(res[0].lessons);
    });
    await getClassesuncoming(userInfo.token).then((res) => {
      console.log("Paid classes", res);
      setClasses(res);
    });
    await getClasses(userInfo.token).then((res) => {
      setClasses2(res);
    });
  }, [userInfo.token]);

  const progress = (_class) => {
    if (_class != undefined) {
      var count = 0;
      for (var i = 0; i < _class.length; i++) {
        if (_class[i].paidClassEINR || _class[i].paidClassINR) {
          count += 1;
        }
      }
      var percenatge = (count / _class.length) * 100;
      //console.log(percenatge, count);
      return Math.floor(percenatge);
    } else return "0";
  };
  const showBadge = () => {
    if (classes2 != undefined) {
      var count = 0;
      for (var i = 0; i < classes2.length; i++) {
        if (classes2[i].paidClassEINR || classes2[i].paidClassINR) {
          count += 1;
        }
      }
      if (count <= 4) {
        return (
          <>
            <img
              src={Trainee}
              alt="badge"
              className="current_badge img-fluid"
            />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge1Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 4 && count <= 8) {
        return (
          <>
            <img src={badge1} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge2Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 8 && count <= 16) {
        return (
          <>
            <img src={badge2} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge3Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 16 && count <= 24) {
        return (
          <>
            <img src={badge3} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge4Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 24 && count <= 36) {
        return (
          <>
            <img src={badge4} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge5Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 36 && count <= 48) {
        return (
          <>
            <img src={badge5} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge6Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 48 && count <= 72) {
        return (
          <>
            <img src={badge6} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge7Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 72 && count <= 88) {
        return (
          <>
            <img src={badge7} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge8Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 88 && count <= 108) {
        return (
          <>
            <img src={badge8} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge9Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 108 && count <= 124) {
        return (
          <>
            <img src={badge9} alt="badge" className="current_badge img-fluid" />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge10Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 124 && count <= 144) {
        return (
          <>
            <img
              src={badge10}
              alt="badge"
              className="current_badge img-fluid"
            />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge11Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 144 && count <= 176) {
        return (
          <>
            <img
              src={badge11}
              alt="badge"
              className="current_badge img-fluid"
            />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge12Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 176 && count <= 212) {
        return (
          <>
            <img
              src={badge12}
              alt="badge"
              className="current_badge img-fluid"
            />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge13Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 212 && count <= 254) {
        return (
          <>
            <img
              src={badge13}
              alt="badge"
              className="current_badge img-fluid"
            />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge14Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else if (count > 254 && count < 300) {
        return (
          <>
            <img
              src={badge14}
              alt="badge"
              className="current_badge img-fluid"
            />
            <AiOutlineArrowRight color="#00b4db" size={40} />
            <img
              src={badge15Locked}
              alt="badge"
              className="next_badge img-fluid"
            />
          </>
        );
      } else {
        return (
          <>
            <img src={badge15} alt="badge" className="next_badge img-fluid" />
          </>
        );
      }
    }
  };
  const userName = (name) => {
    if (name) {
      var words = name.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ");
    } else {
      return "";
    }
  };
  const getStatus = (item, index) => {
    if (index == 1) {
      return <h4>{item.slot}</h4>;
    } else {
      var today = new Date().getTime();
      var classTime = new Date(item.slot).getTime();
      var diffrence = classTime - today;
      if (diffrence > 600000) {
        return (
          <CountDownTimer
            date={item.slot.substring(0, 15)}
            time={item.slot.slice(15)}
          />
        );
      } else if (diffrence >= 0 && diffrence < 600000) {
        return (
          <TkButton
            name={"Join Now"}
            style={{ backgroundColor: "#60B91F" }}
            _type="submit"
            _onBtnClick={() => window.open(item.teacherID.meetingLink)}
          />
        );
      } else {
        return (
          <TkButton
            name={"On Going"}
            style={{ backgroundColor: "#e6ca17" }}
            _type="submit"
            _onBtnClick={() => window.open(item.teacherID.meetingLink)}
          />
        );
      }
    }
  };
  return (
    <div className="dashboard w_87 mx-auto">
      <div className="ds_top">
        <WelcomeMsg
          _class="grid"
          name={userInfo.userData.name}
          payment={userInfo.userData.payment}
        />

        <div className="profile_info_box d-flex flex-column col-lg-4 col-sm-4 col-12 align-items-center mx-auto">
          <ProfileImgCover />

          <h5 className=" mt-2">
            <b>{userName(userInfo.userData.name)}</b>
          </h5>
          <h6>{userInfo.userData.grade} Grade</h6>

          <div className="level d-flex mt-3 align-items-center justify-content-between">
            {showBadge(classes2)}
          </div>

          <h6 className="mt-5">Current Curriculum</h6>
          <div className="cicular_progress">
            <CircularProgressbar
              value={progress(classes2)}
              maxValue={100}
              text={`${progress(classes2)}%`}
            />
          </div>
        </div>

        <div className="upcoming_class w-100">
          <h4>
            <b>Upcoming Class</b>
          </h4>

          <Carousel
            // arrows
            showDots={true}
            slidesToSlide={1}
            draggable
            removeArrow={true}
            responsive={responsive}
            // itemClass="carousel_item"
          >
            {classes.map((item, index) => {
              return (
                <UpcomingClassItem
                  classNo={`Class ` + item.classNo}
                  desc={curriculum[item.classNo - 1].title}
                  content={getStatus(item, index)}
                />
              );
            })}

            {/* <UpcomingClassItem
              classNo='Class 2'
              desc='   Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione,
          }
          }
        consectetur?'
              content={
                <TkButton
                  name={'Join Now'}
                  style={{ backgroundColor: '#60B91F' }}
                  _type='submit'
                  // _onBtnClick={() => alert("submit")}
                />
              }
            />
            <UpcomingClassItem
              classNo='Class 3'
              desc='   Lorem ipsum dolor, sit amet consectetur adipisicing elit'
              content={<CountDownTimer />}
            />

            <UpcomingClassItem
              classNo='Class 2'
              desc='   Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione,
          
        consectetur?'
              content={
                <TkButton
                  name={'Join Now'}
                  style={{ backgroundColor: '#60B91F' }}
                  _type='submit'
                  // _onBtnClick={() => alert("submit")}
                />
              }
            />
            <UpcomingClassItem
              classNo='Class 3'
              desc='   Lorem ipsum dolor, sit amet consectetur adipisicing elit'
              content={<CountDownTimer />}
            />

            <UpcomingClassItem
              classNo='Class 4'
              desc='   Lorem ipsum dolor, sit amet consectetur adipisicing 
       '
              content={
                <div className='theme_color'>
                  <h6>You will be able to join at</h6>
                  <h6>
                    <b>25 Nov 2020</b>
                  </h6>
                  <h6>
                    <b>1:00 pm</b>
                  </h6>
                </div>
              }
            /> */}
          </Carousel>
        </div>
      </div>
      <div className="roadmap d-flex align-items-center " ref={ref}>
        <img
          src={nextBtn}
          onClick={() => scrollRight()}
          alt="next"
          className="rd_nav_icon"
        />
        <img src={roadmap} alt="roadmap" className="roadmap_img" />
        <img
          src={prevBtn}
          onClick={() => scrollLeft()}
          alt="next"
          className="rd_nav_icon"
        />
      </div>
    </div>
  );
};

export default PaidDashboard;
