import React from "react";

const RescheduleDate = ({ offset, setRescheduleValues, rescheduleValues }) => {
  const date = new Date(new Date().setDate(new Date().getDate() + offset));
  const checkClicked = () => {
    console.log(rescheduleValues.date, date);
    if (rescheduleValues.date != "") {
      if (rescheduleValues.date.toDateString() == date.toDateString()) {
        return "reschedule_date_container_selected";
      } else {
        return "reschedule_date_container";
      }
    }
    return "reschedule_date_container";
  };
  return (
    <div
      className={checkClicked()}
      onClick={() => {
        setRescheduleValues((prevValues) => ({
          ...prevValues,
          date: date,
        }));
      }}
    >
      <p>{date.getDate()}</p>
      <p>{date.toLocaleDateString("en-IN", { month: "short" })}</p>
      <span>{date.toLocaleDateString("en-IN", { weekday: "short" })}</span>
    </div>
  );
};

export default RescheduleDate;
