import React, {useState, useContext} from "react";
import BackNavigation from "../../Common/Components/BackNavigation";
import Base from "../../Common/Components/Base/Base";
import ProjectCard from "./Components/ProjectCard";
import boye from "../../Assets/Project/boye.png";
import {UserContext} from "../../App";
import TkDropDown from "../../Common/Components/DropDown";
import TkButton from "../../Common/Components/TkButton";
import {createProject} from "./ProjectAPICalls";
import StatusModal from "../../Common/Components/StatusModal";
import {useHistory} from "react-router";

const ProjectUpload = () => {
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");
  const [category, setCategory] = useState("");
  const [imgsrc, setImgsrc] = useState("");
  const {userData, token} = useContext(UserContext);
  let history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!file || !title || !category || !description) {
      setStatusModal({
        ...statusModal,
        status: "error",
        showStatusModal: true,
        statusText: "Enter All the fields",
      });

      return;
    }
    const data = new FormData();
    data.append("file", file);
    data.append("title", title);
    data.append("category", category);
    data.append("projectlink", link);
    data.append("description", description);
    createProject(data, token).then((res) => {
      if (res.error) {
        setStatusModal({
          ...statusModal,
          status: "error",
          showStatusModal: true,
          statusText: res.error,
        });
        
        return;
      }
      setTitle("");
      setCategory("");
      setLink("");
      setFile(null);
      setDescription("");
      setStatusModal({
        ...statusModal,
        status: "success",
        showStatusModal: true,
        statusText: "Project Uploaded",
      });
    });
  };
  const onImageChange = (event) => {
    setFile(event.target.files[0]);
    setImgsrc(URL.createObjectURL(event.target.files[0]));
  };

  const data = {
    coverimage: imgsrc === "" ? boye : imgsrc,
    title: title === "" ? "Project Name" : title,
    description: description === "" ? "Project Description" : description,
  };
  return (
    <Base>
      <BackNavigation />
      <div className=" d-flex flex-sm-row flex-column w_90 mx-auto justify-content-between project_upload_container">
        <div className="p_60 col-sm-4 col-12 d-flex align-items-center flex-column">
          <h4>
            <b>Your Project Preview</b>
          </h4>
          <ProjectCard projectData={data} isPreview={true} />
        </div>
        <div className="p_60 col-sm-7 col-12 d-flex project_upload  flex-column">
          <form className="mt-4">
            <div className="form-group ticket_desc">
              <label>Project Title*</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="form-group ticket_desc">
              <label>Project Description*</label>
              <textarea
                id=""
                rows="10"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group ">
              <span>Cover Image*</span>
              <label className="cover_img">
                <span className="theme_color"> Upload Cover Image</span>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onImageChange(e)}
                />
              </label>
            </div>
            <div className="form-group ticket_desc">
              <TkDropDown
                label="Category*"
                _onClick={setCategory}
                value={category}
                items={[
                  "Augmented Reality",
                  "Artificial Intelligence",
                  "Web Development",
                  "Mobile Apps",
                  "Virtual Reality",
                  "Mixed Reality",
                  "Java Programming",
                  "Game Development",
                  "Programming Fundamentals",
                  "Design & Multimedia",
                  "Python Programminng",
                  "Others",
                  "Web Dev - Backend",
                  "Game Dev With AI",
                  "Other Issues",
                ]}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div className="form-group ticket_desc">
              <label>Project Link</label>
              <input
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <TkButton
              name={"Save Project"}
              style={{backgroundColor: "#00b4db"}}
              _class="mt-5"
              _type="submit"
              _onBtnClick={(e) => handleSubmit(e)}
            />
          </form>
        </div>
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() => {
            statusModal.status === "error"
              ? setStatusModal({...statusModal, showStatusModal: false})
              : history.goBack();
          }}
        />
      </div>
    </Base>
  );
};

export default ProjectUpload;
