import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { validateEmail } from '../../Common/Utils/Helper'
import { InputFeild } from './Components/InputFeild'
import LoginBtn from './Components/LoginBtn'
import { authenticate, loginWithOTP, verifyOTP } from './LoginAPICalls'
import LoginBase from './LoginBase'

const LoginWithOTP = () => {
  const [email, setEmail] = useState('')
  // const [OTP, setOTP] = useState('')
  const [OTPerror, setOTPerror] = useState('')
  const [emailError, setEmailError] = useState('')
  const [OTPToken, setOTPToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [itemIndex, setitemIndex] = useState(0)
  const [digit1, setDigit1] = useState('')
  const [digit2, setDigit2] = useState('')
  const [digit3, setDigit3] = useState('')
  const [digit4, setDigit4] = useState('')
  const [digit5, setDigit5] = useState('')
  const [digit6, setDigit6] = useState('')

  const history = useHistory()

  const onChange = function (event, setter) {
    let value = event.target.value
    if (value.length > 1) return
    if (value !== '' && (value < '0' || value > '9')) return
    setter(value)
  }

  const jumpToId = function (id) {
    if (id < 1 || id > 6) return
    let item = document.getElementById('digit' + id)
    if (!item) return
    item.focus()
    item.select()
  }

  const onKeyDown = function (event) {
    let value = event.target.value
    let id = event.target.getAttribute('id')
    let prevId = parseInt(id.substring(5)) - 1
    let nextId = parseInt(id.substring(5)) + 1
    if (event.keyCode === 8 && value === '') return jumpToId(prevId)
    if (value !== '' && '0' <= value && value <= '9') return jumpToId(nextId)
  }

  const onEmailSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    if (!validateEmail(email)) {
      setEmailError('Email not valid')
    } else {
      loginWithOTP(email)
        .then((response) => {
          if (response.status === 200) {
            setOTPToken(response.data.token)
            setitemIndex(1)
          } else {
            alert(response)
          }
        })
        .catch((err) => {
          setEmailError('We cannot find your email')
        })
        .finally(() => {
          setLoading(false)
          setEmailError('')
        })
    }
  }

  const onOTPSubmit = (event) => {
    event.preventDefault()

    let digits = [digit1, digit2, digit3, digit4, digit5, digit6]
    let OTP = ''
    for (let i = 0; i < digits.length; ++i) {
      let digit = digits[i]
      if (!('0' <= digit && digit <= '9')) return
      OTP += digit
    }

    setLoading(true)
    OTP === ''
      ? setOTPerror('Enter OTP first')
      : verifyOTP(email, parseInt(OTP), OTPToken)
          .then((response) => {
            if (response.status === 200) {
              setLoading(false)
              // setValues({...values, loading: false});
              authenticate({ email: email, token: response.data.token }, () => {
                history.push('/dashboard')
              })
            } else {
              alert('otp', response.data.message)
            }
          })
          .catch((err) => {
            setOTPerror('Wrong OTP')
          })
          .finally(() => {
            setLoading(false)
            // setValues({...values, loading: false});
          })
  }
  const AskOTPView = () => {
    return (
      <LoginBase
        topsection={
          <>
            <h1 className='login_h1'>Enter OTP</h1>
            <p className='login_p'>
              OTP has been sent to your Phone or Email id
            </p>
          </>
        }
        children={
          <>
            {/* <InputFeild
              type='number'
              placeholder={'OTP'}
              value={OTP}
              onChange={(e) => setOTP(e.target.value)}
            /> */}

            <div className='login__with__otp__input-container'>
              <input
                id='digit1'
                type='number'
                value={digit1}
                onKeyDown={onKeyDown}
                onChange={(event) => onChange(event, setDigit1)}
              />
              <input
                id='digit2'
                type='number'
                value={digit2}
                onKeyDown={onKeyDown}
                onChange={(event) => onChange(event, setDigit2)}
              />
              <input
                id='digit3'
                type='number'
                value={digit3}
                onKeyDown={onKeyDown}
                onChange={(event) => onChange(event, setDigit3)}
              />
              <input
                id='digit4'
                type='number'
                value={digit4}
                onKeyDown={onKeyDown}
                onChange={(event) => onChange(event, setDigit4)}
              />
              <input
                id='digit5'
                type='number'
                value={digit5}
                onKeyDown={onKeyDown}
                onChange={(event) => onChange(event, setDigit5)}
              />
              <input
                id='digit6'
                type='number'
                value={digit6}
                onKeyDown={onKeyDown}
                onChange={(event) => onChange(event, setDigit6)}
              />
            </div>

            <h6 className='text-danger'> {OTPerror}</h6>

            <LoginBtn
              type='button'
              btnName='SUBMIT'
              className={'mt-3'}
              onClick={(e) => onOTPSubmit(e)}
            />
          </>
        }
        bottomsection={
          <h4 onClick={() => history.push('/login')}>
            <b> {'< Back to Login'}</b>
          </h4>
        }
      />
    )
  }
  const AskEmailView = () => {
    return (
      <LoginBase
        topsection={
          <>
            <h1 className='login_h1'>Sign in with OTP</h1>
            <p className='login_p'>
              Enter your email and we'll send you a OTP to your Phone or Email
              id
            </p>
          </>
        }
        children={
          <>
            <InputFeild
              type='email'
              placeholder={'Email'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <h6 className='text-danger'> {emailError}</h6>

            <LoginBtn
              type={'button'}
              btnName={'NEXT'}
              className={'mt-3'}
              onClick={(e) => onEmailSubmit(e)}
            />
          </>
        }
        bottomsection={
          <h4 onClick={() => history.push('/login')}>
            <b> {'< Back to Login'}</b>
          </h4>
        }
      />
    )
  }

  const viewRender = [AskEmailView(), AskOTPView()]
  return viewRender[itemIndex]
}

export default LoginWithOTP
