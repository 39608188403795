import { base_route } from '../../backend'
import React, { useContext, useEffect, useState } from 'react'
import TkButton from '../../Common/Components/TkButton'
import TkSection from '../../Common/Components/TkSection'
import WelcomeMsg from './Components/WelcomeMsg'
import tower from '../../Assets/Dashboard/tower.png'
import WatchNow from '../../Assets/Dashboard/WatchNow.png'
import Calendar from '../../Assets/Dashboard/calendar.png'
import BoywithPC from '../../Assets/Dashboard/Boy&PC.png'
import CheckPcModal from '../TechSupport/Components/CheckPcModal'
import { useHistory } from 'react-router-dom'
import CountDownTimer from './Components/CountDownTimer'
import { convertDate, openInNewTab, googleUrl } from '../../Common/Utils/Helper'
import BookSlotModal from './Components/BookSlotModal'
import { UserContext } from '../../App'
import { getSchedule, getFreeSlot } from './DashboardAPICalls'
import { isAuthenticated } from '../Login/LoginAPICalls'
import CongratulationImg from '../../Assets/Dashboard/Congratulation.svg'
import PaperBlastImg from '../../Assets/Dashboard/color-paper-blast.svg'
import OopsImg from '../../Assets/Dashboard/OOPS.svg'
import RescheduleImg from '../../Assets/Dashboard/Reschedule.svg'
import Message from './Components/Message'
import Feedback from './Components/Feedback'
import Reschedule from './Components/Reschedule'
import StatusModal from '../../Common/Components/StatusModal'

const FreeDashboard = () => {
  const { userData } = useContext(UserContext)
  const [showModal, setshowModal] = useState(false)
  const [showBookModal, setShowBookModal] = useState(false)
  const [freeslot, setFreeslot] = useState([])
  const [bookingDetail, setbookingDetail] = useState(null)
  const [bookingTime, setbookingTime] = useState('')
  const [bookingDate, setbookingDate] = useState('')
  const [joined, setJoined] = useState(false)
  //new Date(new Date().getTime() + 100 * 24 * 60 * 60 * 1000).toDateString()
  const [meetingLink, setmeetingLink] = useState('')
  const [only, setOnly] = useState(0)
  const [isTrialComplete, setIsTrialComplete] = useState(false)
  const [isTrialCancelled, setIsTrialCancelled] = useState(true)
  const [isFeedbackBtnClicked, setIsFeedbackBtnClicked] = useState(false)
  const [slotID, setSlotID] = useState('')
  const [isRescheduleBtnClicked, setIsRescheduleBtnClicked] = useState(false)
  const [feedbackValues, setFeedbackValues] = useState({
    quality: '',
    interested: '',
    teacherRating: 0,
    overallRating: 0,
    suggestion: '',
  })
  const [rescheduleValues, setRescheduleValues] = useState({
    date: '',
    time: '',
  })

  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: '',
    statusText: '',
  })

  console.log(slotID)
  const history = useHistory()
  const handleCheckPc = () => {
    setshowModal(true)
  }
  const onModalBtnClick = () => {
    setshowModal(!showModal)
  }
  const onBookModalClick = () => {
    setShowBookModal(!showBookModal)
  }
  const { token } = isAuthenticated()

  const updateJoined = async (classID) => {
    await fetch(`${base_route}/slot/updatestudentjoined`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ classID }),
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log('Response', resp)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(async () => {
    if (token != undefined && token != null) {
      await getSchedule(token).then((data) => {
        if (data[0]) {
          console.log('data', data[0])
          setbookingDetail(data)
          setbookingTime(data[0].bookingTime)
          setbookingDate(data[0].bookingDate)
        }
      })
      await getFreeSlot(token).then((data) => {
        console.log('slot data', data)
        if (data.teacherID) {
          setFreeslot(data)
          setbookingDate(data.slot.slice(0, 16))
          setbookingTime(data.slot.slice(16))
          setmeetingLink(data.teacherID.meetingLink)
        }
      })
    }
  }, [token, only])

  const classStatus = (date, time) => {
    if (date != '' && time != '') {
      var T = ''
      for (var i = 0; i < time.length; i++) {
        if (time[i] == 'A' || time[i] == 'P') {
          T += ' '
          T += time[i]
        } else {
          T += time[i]
        }
      }
      var d = date + ' ' + T
      var actual = new Date().getTime()
      var difference = new Date(d).getTime() - actual
      console.log(difference, freeslot)
      if (difference > 10 * 60 * 1000) {
        return 'Join Now'
      } else {
        if (freeslot.length == 0) {
          return 'Sorry'
        } else {
          if (freeslot.isstudentjoined) {
            if (freeslot.trailCompleted) {
              return 'Completed'
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return 'Cancelled'
            } else {
              return 'On Going'
            }
          } else {
            if (freeslot.trailCompleted) {
              return 'Completed'
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return 'Cancelled'
            } else {
              return 'Join Now'
            }
          }
        }
      }
    } else {
      return 'Loading'
    }
  }

  const JoinClass = (date, time) => {
    //setJoined(true);
    if (date != '' && time != '') {
      var T = ''
      for (var i = 0; i < time.length; i++) {
        if (time[i] == 'A' || time[i] == 'P') {
          T += ' '
          T += time[i]
        } else {
          T += time[i]
        }
      }
      var d = date + ' ' + T
      var actual = new Date().getTime()
      var difference = new Date(d).getTime() - actual
      if (difference > 10 * 60 * 1000) {
        return
      } else {
        if (freeslot == null) {
          return
        } else {
          if (freeslot.isstudentjoined) {
            if (freeslot.trailCompleted) {
              setJoined(true)
              return
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return
            } else {
              window.open(meetingLink)
              setJoined(true)
              return
            }
          } else {
            if (freeslot.trailCompleted) {
              setJoined(true)
              return
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return
            } else {
              updateJoined(freeslot._id)
              setJoined(true)
              window.open(meetingLink)
              return
            }
          }
        }
      }
    } else {
      return
    }
  }

  const showComponent = (date, time) => {
    console.log('show component', date, time)
    if (date != null && time != null && date != '' && time != '') {
      console.log('enterd')
      var T = ''
      for (var i = 0; i < time.length; i++) {
        if (time[i] == 'A' || time[i] == 'P') {
          T += ' '
          T += time[i]
        } else {
          T += time[i]
        }
      }
      var d = date + ' ' + T
      var actual = new Date().getTime()
      var difference = new Date(d).getTime() - actual
      console.log('difference at show componet ', difference)
      if (difference > 10 * 60 * 1000) {
        return (
          <TkSection
            text={
              <>
                <h6 className='heading'>
                  <b>
                    You will be able to join at {bookingDate}, {bookingTime}
                  </b>
                </h6>
                <span
                  className='mt-2 text-success'
                  onClick={() =>
                    openInNewTab(googleUrl(bookingDate, bookingTime))
                  }
                >
                  Add to Calendar
                </span>
              </>
            }
            content={
              <div className='counter_container'>
                <CountDownTimer
                  date={bookingDate}
                  time={bookingTime}
                  only={only}
                  setOnly={setOnly}
                  joined={joined}
                />
                <span className='ml-1'>Remaining</span>
              </div>
            }
            Btn={
              <TkButton
                name={classStatus(bookingDate, bookingTime)}
                style={{
                  backgroundColor: `${freeClassStatus(
                    bookingDate,
                    bookingTime
                  )}`,
                  maxWidth: 210,
                }}
                _class='my-3'
                _type='submit'
                _onBtnClick={() => JoinClass(bookingDate, bookingTime)}
              />
            }
            img={Calendar}
            className='hide_on_mobile'
          />
        )
      } else {
        if (freeslot == null) {
          return <h1>No teacher assinged</h1>
        } else {
          if (freeslot.isstudentjoined) {
            if (freeslot.trailCompleted && freeslot.sfeedback.length <= 0) {
              return (
                <Message
                  titleImg={CongratulationImg}
                  illustration={PaperBlastImg}
                  subtitle='You have successfully completed your trial class!'
                  content='We hope you loved our trial class. Thanks for choosing us! We would love your feedback. Your feedback helps us to improve our services'
                  btnValue='FEEDBACK'
                  handleBtnClick={() => {
                    setIsFeedbackBtnClicked(true)
                    setSlotID(freeslot._id)
                  }}
                />
              )
            } else if (
              freeslot.trailCompleted &&
              freeslot.sfeedback.length >= 1
            ) {
              return (
                <Message
                  titleImg={CongratulationImg}
                  illustration={PaperBlastImg}
                  subtitle='You have successfully completed your trial class!'
                  content='We hope you loved our trial class. Thanks for choosing us! We would love your feedback. Your feedback helps us to improve our services'
                  btnValue='FEEDBACK SUBMITTED'
                  handleBtnClick={() => {
                    return
                  }}
                />
              )
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return (
                <TkSection
                  text={
                    <>
                      <h6 className='heading'>
                        <b>
                          You will be able to join at {bookingDate},{' '}
                          {bookingTime}
                        </b>
                      </h6>
                      <span
                        className='mt-2 text-success'
                        onClick={() =>
                          openInNewTab(googleUrl(bookingDate, bookingTime))
                        }
                      >
                        Add to Calendar
                      </span>
                    </>
                  }
                  content={
                    <div className='counter_container'>
                      <CountDownTimer
                        date={bookingDate}
                        time={bookingTime}
                        only={only}
                        setOnly={setOnly}
                        joined={joined}
                      />
                      <span className='ml-1'>Remaining</span>
                    </div>
                  }
                  Btn={
                    <TkButton
                      name={classStatus(bookingDate, bookingTime)}
                      style={{
                        backgroundColor: `${freeClassStatus(
                          bookingDate,
                          bookingTime
                        )}`,
                        maxWidth: 210,
                      }}
                      _class='my-3'
                      _type='submit'
                      _onBtnClick={() => JoinClass(bookingDate, bookingTime)}
                    />
                  }
                  img={Calendar}
                  className='hide_on_mobile'
                />
              )
            } else {
              return (
                <TkSection
                  text={
                    <>
                      <h6 className='heading'>
                        <b>
                          You will be able to join at {bookingDate},{' '}
                          {bookingTime}
                        </b>
                      </h6>
                      <span
                        className='mt-2 text-success'
                        onClick={() =>
                          openInNewTab(googleUrl(bookingDate, bookingTime))
                        }
                      >
                        Add to Calendar
                      </span>
                    </>
                  }
                  content={
                    <div className='counter_container'>
                      <CountDownTimer
                        date={bookingDate}
                        time={bookingTime}
                        only={only}
                        setOnly={setOnly}
                        joined={joined}
                      />
                      <span className='ml-1'>Remaining</span>
                    </div>
                  }
                  Btn={
                    <TkButton
                      name={classStatus(bookingDate, bookingTime)}
                      style={{
                        backgroundColor: `${freeClassStatus(
                          bookingDate,
                          bookingTime
                        )}`,
                        maxWidth: 210,
                      }}
                      _class='my-3'
                      _type='submit'
                      _onBtnClick={() => JoinClass(bookingDate, bookingTime)}
                    />
                  }
                  img={Calendar}
                  className='hide_on_mobile'
                />
              )
            }
          } else {
            if (freeslot.trailCompleted) {
              return <h1>Teacher Completed Marked But you have'nt joined</h1>
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return (
                <Message
                  titleImg={OopsImg}
                  illustration={RescheduleImg}
                  subtitle='You hav missed your trial class.'
                  btnValue='RESCHEDULE'
                  content="Coding improves maths. DON'T MISS OUT on essential skills. No worries, here your chance to reschedule your trial again"
                  handleBtnClick={() => {
                    setIsRescheduleBtnClicked(true)
                    setSlotID(freeslot._id)
                  }}
                ></Message>
              )
            } else {
              return (
                <TkSection
                  text={
                    <>
                      <h6 className='heading'>
                        <b>
                          You will be able to join at {bookingDate},{' '}
                          {bookingTime}
                        </b>
                      </h6>
                      <span
                        className='mt-2 text-success'
                        onClick={() =>
                          openInNewTab(googleUrl(bookingDate, bookingTime))
                        }
                      >
                        Add to Calendar
                      </span>
                    </>
                  }
                  content={
                    <div className='counter_container'>
                      <CountDownTimer
                        date={bookingDate}
                        time={bookingTime}
                        only={only}
                        setOnly={setOnly}
                        joined={joined}
                      />
                      <span className='ml-1'>Remaining</span>
                    </div>
                  }
                  Btn={
                    <TkButton
                      name={classStatus(bookingDate, bookingTime)}
                      style={{
                        backgroundColor: `${freeClassStatus(
                          bookingDate,
                          bookingTime
                        )}`,
                        maxWidth: 210,
                      }}
                      _class='my-3'
                      _type='submit'
                      _onBtnClick={() => JoinClass(bookingDate, bookingTime)}
                    />
                  }
                  img={Calendar}
                  className='hide_on_mobile'
                />
              )
            }
          }
        }
      }
    } else {
      return (
        <TkSection
          text={
            <>
              <h6 className='heading'>
                <b>
                  You will be able to join at {bookingDate}, {bookingTime}
                </b>
              </h6>
              <span
                className='mt-2 text-success'
                onClick={() =>
                  openInNewTab(googleUrl(bookingDate, bookingTime))
                }
              >
                Add to Calendar
              </span>
            </>
          }
          content={
            <div className='counter_container'>
              <CountDownTimer
                date={bookingDate}
                time={bookingTime}
                only={only}
                setOnly={setOnly}
                joined={joined}
              />
              <span className='ml-1'>Remaining</span>
            </div>
          }
          Btn={
            <TkButton
              name={classStatus(bookingDate, bookingTime)}
              style={{
                backgroundColor: `${freeClassStatus(bookingDate, bookingTime)}`,
                maxWidth: 210,
              }}
              _class='my-3'
              _type='submit'
              _onBtnClick={() => JoinClass(bookingDate, bookingTime)}
            />
          }
          img={Calendar}
          className='hide_on_mobile'
        />
      )
    }
  }

  const freeClassStatus = (date, time) => {
    if (date != null && time != null && date != '' && time != '') {
      console.log('enterd')
      var T = ''
      for (var i = 0; i < time.length; i++) {
        if (time[i] == 'A' || time[i] == 'P') {
          T += ' '
          T += time[i]
        } else {
          T += time[i]
        }
      }
      var d = date + ' ' + T
      var actual = new Date().getTime()
      var difference = new Date(d).getTime() - actual
      // console.log("difference", difference);
      if (difference > 10 * 60 * 1000) {
        return '#d6d6c2'
      } else {
        if (freeslot == null) {
          return '#d6d6c2'
        } else {
          if (freeslot.isstudentjoined) {
            if (freeslot.trailCompleted) {
              return '#11b840'
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return '#ff1a1a'
            } else {
              return '#d3d613'
            }
          } else {
            if (freeslot.trailCompleted) {
              return '#11b840'
            } else if (freeslot.halfCompleted || freeslot.cancelled) {
              return '#ff1a1a'
            } else {
              return '#33cc33'
            }
          }
        }
      }
    } else {
      return '#d6d6c2'
    }
  }

  return (
    <div className='w_85 mx-auto'>
      <WelcomeMsg _class='n_grid' name={userData.name} />

      {isFeedbackBtnClicked && (
        <Feedback
          setIsFeedbackBtnClicked={setIsFeedbackBtnClicked}
          setFeedbackValues={setFeedbackValues}
          feedbackValues={feedbackValues}
          slotID={slotID}
          statusModal={statusModal}
          setStatusModal={setStatusModal}
        ></Feedback>
      )}
      {isRescheduleBtnClicked && (
        <Reschedule
          setIsRescheduleBtnClicked={setIsRescheduleBtnClicked}
          rescheduleValues={rescheduleValues}
          setRescheduleValues={setRescheduleValues}
          slotID={slotID}
          statusModal={statusModal}
          setStatusModal={setStatusModal}
        ></Reschedule>
      )}
      {userData.hasLaptop ? (
        <>
          {showComponent(bookingDate, bookingTime)}
          <TkSection
            text={
              <>
                <h6 className='heading'>
                  <b>Set up your PC</b>
                </h6>
                <span className='mt-2 desc'>
                  Check your network connection and computer set up to have in
                  class experience
                </span>
              </>
            }
            Btn={
              <TkButton
                name={'CHECK NOW'}
                style={{ backgroundColor: '#60B91F', maxWidth: 210 }}
                _class='my-3'
                _type='submit'
                _onBtnClick={() => handleCheckPc()}
              />
            }
            img={tower}
          />
          <TkSection
            text={
              <>
                <h6 className='heading'>
                  <b>Haven't bought our courses</b>
                </h6>
                <span className='mt-2 desc'>
                  You can always learn from our freemium course
                </span>
              </>
            }
            Btn={
              <TkButton
                name={'WATCH NOW'}
                style={{ backgroundColor: '#60B91F', maxWidth: 210 }}
                _class='my-3'
                _type='submit'
                _onBtnClick={() => history.push('/courses/free')}
              />
            }
            img={WatchNow}
          />
        </>
      ) : (
        <>
          <TkSection
            text={
              <>
                <h6>
                  <b>Sorry, we are unable to schedule your demo class.</b>
                </h6>
                <h6>
                  <b>Laptop/PC is must to attend the class.</b>
                </h6>
                <span className='mt-2'>
                  No Worries once you are ready with your setup , please do call
                  us to to book the class
                </span>
                <span className='mt-2'>+91 2653561742</span>
              </>
            }
            Btn={
              <TkButton
                name={'BOOK YOUR SLOT'}
                style={{ backgroundColor: '#60B91F', maxWidth: 210 }}
                _class='my-3'
                _type='submit'
                _onBtnClick={() => setShowBookModal(true)}
              />
            }
            img={BoywithPC}
          />
          <TkSection
            text={
              <>
                <h6>
                  <b>Haven't bought our courses</b>
                </h6>
                <span className='mt-2 desc'>
                  You can always learn from our freemium course
                </span>
              </>
            }
            Btn={
              <TkButton
                name={'WATCH NOW'}
                style={{ backgroundColor: '#60B91F', maxWidth: 210 }}
                _class='my-3'
                _type='submit'
                _onBtnClick={() => history.push('/courses/free')}
              />
            }
            img={WatchNow}
          />
        </>
      )}
      <BookSlotModal showModal={showBookModal} closeModal={onBookModalClick} />
      <CheckPcModal showModal={showModal} closeModal={onModalBtnClick} />
      <StatusModal
        showModal={statusModal.showStatusModal}
        text={statusModal.statusText}
        status={statusModal.status}
        closeModal={() =>
          setStatusModal({ ...statusModal, showStatusModal: false })
        }
      />
    </div>
  )
}

export default FreeDashboard
