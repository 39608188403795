import React from "react";
import "./Components.css";

const ProfileItem = ({title, content}) => {
  return (
    <div className="my-5 ">
      <h5 className="profile_title mb-2">
        <b>{title}</b>
      </h5>
      {content}
    </div>
  );
};

export default ProfileItem;
