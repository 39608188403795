import React from "react";
import Logo from "../../Assets/Sidebar/logo.png";
import "./Login.css";

const LoginBase = ({children, topsection, bottomsection}) => {
  return (
    <>
      <div className="navbar login">
        <a href="https://www.techokids.com"><img src={Logo} alt="logo" className="img-fluid" /></a>
      </div>
      <div className="login_page d-flex justify-content-center align-items-center">
        <div className="login_page_form d-flex justify-content-around  flex-column align-items-center text-center">
          <div className="login_page_top">{topsection}</div>
          <div className="login_form">{children}</div>
          <div className="login_bottom mt-4">{bottomsection}</div>
        </div>
      </div>
    </>
  );
};

export default LoginBase;
