import React, { useState, useEffect, useRef } from 'react'
import './Component.css'

const CountDownTimer = ({ date, time, only, setOnly, joined }) => {
  let T = ''

  const [ms, setMs] = useState(1)
  const hour = useRef('00')
  const minute = useRef('00')
  const sec = useRef('00')
  const days = useRef('00')
  const countRef = useRef(null)

  useEffect(() => {
    const second = Math.floor(ms / 1000)
    const getSeconds = `0${second % 60}`.slice(-2)
    const minutes = `${Math.floor(second / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor((second / 3600) % 24)}`.slice(-2)
    const getdays = `0${Math.floor(second / (3600 * 24))}`.slice(-2)
    hour.current = getHours
    days.current = getdays
    minute.current = getMinutes
    sec.current = getSeconds
    if (joined) {
      clearInterval(countRef.current)
      hour.current = '00'
      days.current = '00'
      minute.current = '00'
      sec.current = '00'
    }
    if (ms <= 0) {
      clearInterval(countRef.current)
      hour.current = '00'
      days.current = '00'
      minute.current = '00'
      sec.current = '00'
    }
  }, [ms, joined])

  useEffect(() => {
    if (
      time != null &&
      time != undefined &&
      date != undefined &&
      date != null &&
      time != '' &&
      date != ''
    ) {
      for (var i = 0; i < time.length; i++) {
        if (time[i] == 'A' || time[i] == 'P') {
          T += ' '
          T += time[i]
        } else {
          T += time[i]
        }
      }
      var S = new Date(date + ' ' + T)
      countRef.current = setInterval(() => {
        if (ms <= 0) {
          setMs(0)
        } else if (ms == 600000) {
          window.location.reload()
        } else {
          setMs(S - new Date())
        }
      })
    }
  }, [time, date])

  const timerElement = (text, place1, place2) => {
    return (
      <div className='timer_component'>
        <div className='timer'>
          <span className='time_text'>{text}</span>
          <div className=' d-flex justify-content-center'>
            <div className='timer_item'>{place1}</div>
            <div className='timer_item'>{place2}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='timer_container'>
      {timerElement('Days', days.current[0], days.current[1])}
      {timerElement('hours', hour.current[0], hour.current[1])}
      {timerElement('minutes', minute.current[0], minute.current[1])}
      {timerElement('seconds', sec.current[0], sec.current[1])}
    </div>
  )
}

export default CountDownTimer
