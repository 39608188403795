import React, { useContext, useEffect, useState } from 'react'
import Base from '../../Common/Components/Base/Base'
import ProfileImgCover from '../../Common/Components/ProfileImgCover'
import TkButton from '../../Common/Components/TkButton'
import './Profile.css'
import { MdLocationOn } from 'react-icons/md'
import { FaGraduationCap } from 'react-icons/fa'
import Trainee from '../../Assets/Badge/Trainee.png'
import badge1 from '../../Assets/Badge/Artboard 1.png'
import badge2 from '../../Assets/Badge/Artboard 2.png'
import badge3 from '../../Assets/Badge/Artboard 3.png'
import badge4 from '../../Assets/Badge/Artboard 4.png'
import badge5 from '../../Assets/Badge/Artboard 5.png'
import badge6 from '../../Assets/Badge/Artboard 6.png'
import badge7 from '../../Assets/Badge/Artboard 7.png'
import badge8 from '../../Assets/Badge/Artboard 8.png'
import badge9 from '../../Assets/Badge/Artboard 9.png'
import badge10 from '../../Assets/Badge/Artboard 10.png'
import badge11 from '../../Assets/Badge/Artboard 11.png'
import badge12 from '../../Assets/Badge/Artboard 12.png'
import badge13 from '../../Assets/Badge/Artboard 13.png'
import badge14 from '../../Assets/Badge/Artboard 14.png'
import badge15 from '../../Assets/Badge/Artboard 15.png'
import badge1Locked from '../../Assets/Badge/Artboard 1 copy.png'
import badge2Locked from '../../Assets/Badge/Artboard 2 copy.png'
import badge3Locked from '../../Assets/Badge/Artboard 3 copy.png'
import badge4Locked from '../../Assets/Badge/Artboard 4 copy.png'
import badge5Locked from '../../Assets/Badge/Artboard 5 copy.png'
import badge6Locked from '../../Assets/Badge/Artboard 6 copy.png'
import badge7Locked from '../../Assets/Badge/Artboard 7 copy.png'
import badge8Locked from '../../Assets/Badge/Artboard 8 copy.png'
import badge9Locked from '../../Assets/Badge/Artboard 9 copy.png'
import badge10Locked from '../../Assets/Badge/Artboard 10 copy.png'
import badge11Locked from '../../Assets/Badge/Artboard 11 copy.png'
import badge12Locked from '../../Assets/Badge/Artboard 12 copy.png'
import badge13Locked from '../../Assets/Badge/Artboard 13 copy.png'
import badge14Locked from '../../Assets/Badge/Artboard 14 copy.png'
import badge15Locked from '../../Assets/Badge/Artboard 15 copy.png'
import { BsArrowRightShort } from 'react-icons/bs'
import ProfileItem from './Components/ProfileItem'
import Certificate from './Components/Certificate'
import { FiDownload } from 'react-icons/fi'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import BadgeModal from './Components/BadgeModal'
import { useHistory } from 'react-router-dom'
import CertificateModal from './Components/CertificateModal'
import { UserContext } from '../../App'
import { calculateAge } from '../../Common/Utils/Helper'
import { getClasses, getFreeSlot } from './ProfileAPIcalls'
import AutoScale from 'react-auto-scale'
import jsPDF from 'jspdf'
import domToImage from 'dom-to-image'
// import { toPng } from 'html-to-image'

const Profile = () => {
  const [showModal, setshowModal] = useState(false)
  const [certificateModal, setCertificateModal] = useState(false)
  const history = useHistory()
  const [istrialComplete, settrial] = useState(false)
  const userInfo = useContext(UserContext)
  const [classes, setClasses] = useState([])

  const onDownloadBtnClick = (e) => {
    domToImage
      .toPng(document.getElementById('certificate'))
      .then(function (dataUrl) {
        const pdf = new jsPDF()
        pdf.addImage(dataUrl, 'PNG', 25, 35)
        pdf.save('certificate.pdf')
      })
  }

  const onModalBtnClick = () => {
    setshowModal(!showModal)
  }
  const onCertificateBtnClick = () => {
    setCertificateModal(!certificateModal)
  }
  useEffect(async () => {
    await getClasses(userInfo.token).then((res) => {
      console.log('Paid classes', res)
      setClasses(res)
    })

    await getFreeSlot(userInfo.token).then((res) => {
      //console.log("Free class", res);
      settrial(res.trailCompleted)
    })
  }, [userInfo.token])

  const userName = (name) => {
    if (name) {
      var words = name.split(' ')
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
      }
      return words.join(' ')
    } else {
      return ''
    }
  }
  const progressCurrent = () => {
    if (classes !== undefined) {
      var count = 0
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].paidClassEINR || classes[i].paidClassINR) {
          count += 1
        }
      }
      var percenatge = (count / classes.length) * 100
      return Math.floor(percenatge)
    } else return 0
  }
  const progressEntire = () => {
    if (classes !== undefined) {
      var count = 0
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].paidClassEINR || classes[i].paidClassINR) {
          count += 1
        }
      }
      var percenatge = (count / 300) * 100
      return Math.floor(percenatge)
    } else return 0
  }
  const showBadges = () => {
    if (classes !== undefined) {
      var count = 0
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].paidClassEINR || classes[i].paidClassINR) {
          count += 1
        }
      }
      console.log(count)
      if (count >= 0 && count < 4) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge1Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
               <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge2Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 4 && count < 8) {
        return (
          <>
            <img
              src={badge1}
              alt='badge2'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge2Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge3Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 8 && count < 16) {
        return (
          <>
            <img
              src={badge2}
              alt='badge2'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge3Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge4Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 16 && count < 24) {
        return (
          <>
            <img
              src={badge3}
              alt='badge3'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge4Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge5Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 24 && count < 36) {
        return (
          <>
            <img
              src={badge4}
              alt='badge4'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge5Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge6Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 36 && count < 48) {
        return (
          <>
            <img
              src={badge5}
              alt='badge5'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge6Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge7Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 48 && count < 72) {
        return (
          <>
            <img
              src={badge6}
              alt='badge6'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge7Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge8Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 72 && count < 88) {
        return (
          <>
            <img
              src={badge7}
              alt='badge7'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge8Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge9Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 88 && count < 108) {
        return (
          <>
            <img
              src={badge8}
              alt='badge8'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge9Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge10Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 108 && count < 124) {
        return (
          <>
            <img
              src={badge9}
              alt='badge9'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge10Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge11Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 124 && count < 144) {
        return (
          <>
            <img
              src={badge10}
              alt='badge10'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge11Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge12Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 144 && count < 176) {
        return (
          <>
            <img
              src={badge11}
              alt='badge11'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge12Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge13Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 176 && count < 212) {
        return (
          <>
            <img
              src={badge12}
              alt='badge12'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge13Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge14Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 212 && count < 254) {
        return (
          <>
            <img
              src={badge13}
              alt='badge13'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge14Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge15Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 254 && count < 300) {
        return (
          <>
            <img
              src={badge14}
              alt='badge14'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img
              src={badge15Locked}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 300) {
        return (
          <>
            <img
              src={badge15}
              alt='badge15'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      }
    }
  }
  const showUpcoming = () => {
    if (classes != undefined) {
      var count = 0
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].paidClassEINR || classes[i].paidClassINR) {
          count += 1
        }
      }
      if (count <= 4) {
        var diff = 4 - 0
        var com = count - 0
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge img-fluid'
            />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge1Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 4 && count <= 8) {
        var diff = 8 - 4
        var com = count - 4
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge1} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge2Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 8 && count <= 16) {
        var diff = 16 - 8
        var com = count - 8
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge2} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge3Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 16 && count <= 24) {
        var diff = 24 - 16
        var com = count - 16
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge4} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge5Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 24 && count <= 36) {
        var diff = 36 - 24
        var com = count - 24
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge4} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge5Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 36 && count <= 48) {
        var diff = 48 - 36
        var com = count - 36
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge5} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge6Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 48 && count <= 72) {
        var diff = 72 - 48
        var com = count - 48
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge6} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge7Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 72 && count <= 88) {
        var diff = 88 - 72
        var com = count - 72
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge7} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge8Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 88 && count <= 108) {
        var diff = 108 - 88
        var com = count - 88
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge8} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge9Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 108 && count <= 124) {
        var diff = 124 - 108
        var com = count - 108
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img src={badge9} alt='badge' className='current_badge img-fluid' />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge10Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 124 && count <= 144) {
        var diff = 144 - 124
        var com = count - 124
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img
              src={badge10}
              alt='badge'
              className='current_badge img-fluid'
            />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge11Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 144 && count <= 176) {
        var diff = 176 - 144
        var com = count - 144
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img
              src={badge11}
              alt='badge'
              className='current_badge img-fluid'
            />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge12Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 176 && count <= 212) {
        var diff = 212 - 176
        var com = count - 176
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img
              src={badge12}
              alt='badge'
              className='current_badge img-fluid'
            />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge13Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 212 && count <= 254) {
        var diff = 254 - 212
        var com = count - 212
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img
              src={badge13}
              alt='badge'
              className='current_badge img-fluid'
            />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge14Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else if (count > 254 && count < 300) {
        var diff = 300 - 254
        var com = count - 254
        var percenatge = Math.floor((com / diff) * 100)
        return (
          <>
            <img
              src={badge14}
              alt='badge'
              className='current_badge img-fluid'
            />
            <div className='progress_bar d-flex justify-content-center align-items-center flex-column'>
              <span>Upcoming Level</span>
              <div class='progress'>
                <div
                  class='progress-bar'
                  role='progressbar'
                  style={{ width: `${percenatge}%` }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <span>{`${percenatge}%`} Completed</span>
            </div>

            <img
              src={badge15Locked}
              alt='badge'
              className='next_badge img-fluid'
            />
          </>
        )
      } else {
        return (
          <>
            <img src={badge15} alt='badge' className='next_badge img-fluid' />
          </>
        )
      }
    }
  }
  return (
    <Base>
      <div className='w_85 mx-auto '>
        <div className='d-flex flex-column profile_box profile '>
          <div className='d-flex align-items-center justify-content-center'>
            <div className='d-flex flex-column'>
              <ProfileImgCover />

              <TkButton
                name={'Edit Profile'}
                style={{ backgroundColor: '#60B91F' }}
                _type='submit'
                _onBtnClick={() => history.push('profile/edit')}
              />
            </div>
            <div className='d-flex flex-column profile_info '>
              <div className='student_intro'>
                <h1 className='theme_color'>
                  <b>{userName(userInfo.userData.name)}</b>
                </h1>

                <div className='d-flex flex-column'>
                  <span>
                    <MdLocationOn size={20} />
                    <b className='px-1'>
                      {userInfo.userData.city} (
                      {calculateAge(userInfo.userData.dob)} age )
                    </b>
                  </span>
                  <span>
                    <FaGraduationCap size={20} />
                    <b className='px-1'>
                      {userInfo.userData.school} ({userInfo.userData.grade}{' '}
                      Grade)
                    </b>
                  </span>
                </div>
              </div>
              <div className='lg_toggle'>
                <div className='level  d-flex mt-4 align-items-center justify-content-between'>
                  {showUpcoming()}
                </div>
              </div>
            </div>
          </div>

          <div className='sm_toggle'>
            <div className='level  d-flex mt-4 align-items-center justify-content-between'>
              {showUpcoming()}
            </div>
          </div>
        </div>
        <ProfileItem
          title={'About Me'}
          content={
            <p className='profile_text mt-2'>{userInfo.userData.about}</p>
          }
        />
        <ProfileItem
          title={'Hobbies'}
          content={
            <p className='profile_text mt-2'>{userInfo.userData.hobbies}</p>
          }
        />
        <ProfileItem
          title={'Badges'}
          content={
            <div className='smbadge_section'>
              <div className='smbadge_container scrollable d-flex align-items-center'>
                {showBadges()}
              </div>
              <TkButton
                name={'Explore More'}
                _class={'mt-2'}
                style={{ backgroundColor: '#101730', height: 35 }}
                _onBtnClick={() => onModalBtnClick()}
              />
            </div>
          }
        />
        <ProfileItem
          title={'Status'}
          content={
            <div className='profile_progress d-flex align-items-center'>
              <div className='d-flex flex-column align-items-center mr-5 mt-2'>
                <span>Current Curriculum</span>
                <CircularProgressbar
                  value={progressCurrent()}
                  maxValue={100}
                  text={`${progressCurrent()}%`}
                  className='mt-3'
                />
              </div>
              <div className='d-flex flex-column align-items-center mt-2 '>
                <span>Entire Curriculum</span>

                <CircularProgressbar
                  value={progressEntire()}
                  maxValue={100}
                  text={`${progressEntire()}%`}
                  className='mt-3'
                />
              </div>
            </div>
          }
        />
        <ProfileItem
          title={'Certificate Earn'}
          content={
            istrialComplete ? (
              <div className='d-flex  flex-sm-row flex-column'>
                <div
                  className='certificate'
                  onClick={() => setCertificateModal(true)}
                >
                  <div style={{ width: '200px', height: 'auto' }}>
                    <AutoScale maxWidth={200} maxHeight={400}>
                      <Certificate
                        name={userName(userInfo.userData.name)}
                      ></Certificate>
                    </AutoScale>
                  </div>
                  <span className='mt-3 '> Trial Certificate</span>

                  <TkButton
                    name={'Download'}
                    style={{
                      backgroundColor: '#00B23B',
                      width: 120,
                      height: 30,
                    }}
                    _class={'sm mt-2'}
                    _onBtnClick={() => onDownloadBtnClick('')}
                    Ricon={<FiDownload />}
                  />
                </div>
              </div>
            ) : (
              <></>
            )
          }
        />

        <CertificateModal
          showModal={certificateModal}
          closeModal={onCertificateBtnClick}
          name={userInfo.userData.name}
          onDownloadBtnClick={onDownloadBtnClick}
          userName={userName}
        />
        <BadgeModal showModal={showModal} closeModal={onModalBtnClick} />
      </div>
    </Base>
  )
}

export default Profile
