import React, { useContext, useEffect, useState } from 'react'
import Base from '../../Common/Components/Base/Base'
import './MyReport.css'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Trainee from '../../Assets/Badge/Trainee.png'
import SkillsUnlocked from './Components/SkillsUnlocked'
import { BsDownload } from 'react-icons/bs'
import { getClasses } from '../Profile/ProfileAPIcalls'
import { UserContext } from '../../App'
import { BsArrowRightShort } from 'react-icons/bs'
import badge1 from '../../Assets/Badge/Artboard 1.png'
import badge2 from '../../Assets/Badge/Artboard 2.png'
import badge3 from '../../Assets/Badge/Artboard 3.png'
import badge4 from '../../Assets/Badge/Artboard 4.png'
import badge5 from '../../Assets/Badge/Artboard 5.png'
import badge6 from '../../Assets/Badge/Artboard 6.png'
import badge7 from '../../Assets/Badge/Artboard 7.png'
import badge8 from '../../Assets/Badge/Artboard 8.png'
import badge9 from '../../Assets/Badge/Artboard 9.png'
import badge10 from '../../Assets/Badge/Artboard 10.png'
import badge11 from '../../Assets/Badge/Artboard 11.png'
import badge12 from '../../Assets/Badge/Artboard 12.png'
import badge13 from '../../Assets/Badge/Artboard 13.png'
import badge14 from '../../Assets/Badge/Artboard 14.png'
import badge15 from '../../Assets/Badge/Artboard 15.png'
import badge1Locked from '../../Assets/Badge/Artboard 1 copy.png'
import badge2Locked from '../../Assets/Badge/Artboard 2 copy.png'
import badge3Locked from '../../Assets/Badge/Artboard 3 copy.png'
import badge4Locked from '../../Assets/Badge/Artboard 4 copy.png'
import badge5Locked from '../../Assets/Badge/Artboard 5 copy.png'
import badge6Locked from '../../Assets/Badge/Artboard 6 copy.png'
import badge7Locked from '../../Assets/Badge/Artboard 7 copy.png'
import badge8Locked from '../../Assets/Badge/Artboard 8 copy.png'
import badge9Locked from '../../Assets/Badge/Artboard 9 copy.png'
import badge10Locked from '../../Assets/Badge/Artboard 10 copy.png'
import badge11Locked from '../../Assets/Badge/Artboard 11 copy.png'
import badge12Locked from '../../Assets/Badge/Artboard 12 copy.png'
import badge13Locked from '../../Assets/Badge/Artboard 13 copy.png'
import badge14Locked from '../../Assets/Badge/Artboard 14 copy.png'
import badge15Locked from '../../Assets/Badge/Artboard 15 copy.png'

const MyReport = () => {
  const [classes, setClasses] = useState([])
  const userInfo = useContext(UserContext)

  useEffect(async () => {
    console.log('from use effect')
    await getClasses(userInfo.token).then((res) => {
      console.log('Paid classes', res)
      setClasses(res)
    })
  }, [userInfo.token])

  const progressCurrent = () => {
    if (classes !== undefined) {
      var count = 0
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].paidClassEINR || classes[i].paidClassINR) {
          count += 1
        }
      }
      var percentage = (count / classes.length) * 100
      return Math.floor(percentage)
    } else return 0
  }
  const progressEntire = () => {
    if (classes !== undefined) {
      var count = 0
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].paidClassEINR || classes[i].paidClassINR) {
          count += 1
        }
      }
      var percentage = (count / 300) * 100
      return Math.floor(percentage)
    } else return 0
  }

  const showBadges = () => {
    if (classes !== undefined) {
      var count = 0
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].paidClassEINR || classes[i].paidClassINR) {
          count += 1
        }
      }
      if (count >= 0 && count < 4) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
          </>
        )
      } else if (count >= 4 && count < 8) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge2' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 8 && count < 16) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge2' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 16 && count < 24) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge2' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 24 && count < 36) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 36 && count < 48) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 48 && count < 72) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 72 && count < 88) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 88 && count < 108) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 108 && count < 124) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />

            <img src={badge9} alt='badge9' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 124 && count < 144) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />

            <img src={badge9} alt='badge9' className='teacher_badge_sm' />

            <img src={badge10} alt='badge10' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 144 && count < 176) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />
            <div className='progress_next_icon'>
              <BsArrowRightShort />
            </div>
            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />

            <img src={badge9} alt='badge9' className='teacher_badge_sm' />

            <img src={badge10} alt='badge10' className='teacher_badge_sm' />

            <img src={badge11} alt='badge11' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 176 && count < 212) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />

            <img src={badge9} alt='badge9' className='teacher_badge_sm' />

            <img src={badge10} alt='badge10' className='teacher_badge_sm' />

            <img src={badge11} alt='badge11' className='teacher_badge_sm' />

            <img src={badge12} alt='badge12' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 212 && count < 254) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />

            <img src={badge9} alt='badge9' className='teacher_badge_sm' />

            <img src={badge10} alt='badge10' className='teacher_badge_sm' />

            <img src={badge11} alt='badge11' className='teacher_badge_sm' />

            <img src={badge12} alt='badge12' className='teacher_badge_sm' />

            <img src={badge13} alt='badge13' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 254 && count < 300) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />

            <img src={badge9} alt='badge9' className='teacher_badge_sm' />

            <img src={badge10} alt='badge10' className='teacher_badge_sm' />

            <img src={badge11} alt='badge11' className='teacher_badge_sm' />

            <img src={badge12} alt='badge12' className='teacher_badge_sm' />

            <img src={badge13} alt='badge13' className='teacher_badge_sm' />

            <img src={badge14} alt='badge14' className='teacher_badge_sm' />
          </>
        )
      } else if (count >= 300) {
        return (
          <>
            <img
              src={Trainee}
              alt='badge'
              className='current_badge teacher_badge_sm img-fluid'
            />

            <img src={badge1} alt='badge1' className='teacher_badge_sm' />

            <img src={badge2} alt='badge2' className='teacher_badge_sm' />

            <img src={badge3} alt='badge3' className='teacher_badge_sm' />

            <img src={badge4} alt='badge4' className='teacher_badge_sm' />

            <img src={badge5} alt='badge5' className='teacher_badge_sm' />

            <img src={badge6} alt='badge6' className='teacher_badge_sm' />

            <img src={badge7} alt='badge7' className='teacher_badge_sm' />

            <img src={badge8} alt='badge8' className='teacher_badge_sm' />

            <img src={badge9} alt='badge9' className='teacher_badge_sm' />

            <img src={badge10} alt='badge10' className='teacher_badge_sm' />

            <img src={badge11} alt='badge11' className='teacher_badge_sm' />

            <img src={badge12} alt='badge12' className='teacher_badge_sm' />

            <img src={badge13} alt='badge13' className='teacher_badge_sm' />

            <img src={badge14} alt='badge14' className='teacher_badge_sm' />

            <img src={badge15} alt='badge15' className='teacher_badge_sm' />
          </>
        )
      }
    }
  }

  return (
    <Base>
      <div className='w_85 mx-auto my_report'>
        <h1 style={{marginBottom:'1rem'}}>
          <b> My Report</b>
        </h1>
     
        <div className='report_progress d-flex justify-content-between '>
          <div className='d-flex flex-column align-items-center report_progress_item   mt-2'>
            <span>Current Curriculum</span>
            <CircularProgressbar
              value={progressCurrent()}
              maxValue={100}
              text={`${progressCurrent()}%`}
              className='mt-3'
            />
          </div>
          <div className='d-flex flex-column align-items-center report_progress_item  mt-2 '>
            <span>Entire Curriculum</span>

            <CircularProgressbar
              value={progressEntire()}
              maxValue={100}
              text={`${progressEntire()}%`}
              className='mt-3'
            />
          </div>
          <div className='d-flex flex-column align-items-center report_progress_item mt-2 '>
            <span>Project Completed</span>

            <CircularProgressbar
              value={0}
              maxValue={10}
              text={`${0}%`}
              className='mt-3'
            />
          </div>
        </div>

        <h4 className='mt-5'>Achievement Badges</h4>
        <div className='smbadge_section d-flex mt-5'>
          <div className='smbadge_container d-flex flex-wrap report_badges'>
            {showBadges()}
          </div>
        </div>
        <h4 className='mt-5'>Skills Unlocked</h4>
        <div className='mt-5 skills_unlocked'>
          <div className='skill_unlocked_container  '>
            <SkillsUnlocked name='JAVASCRIPT' unlocked={0} />
            <SkillsUnlocked name='AI' unlocked={0} />
            <SkillsUnlocked name='WEB DEVELOPMENT' unlocked={0} />
            <SkillsUnlocked name='MACHINE LEARNING' unlocked={0} />
            <SkillsUnlocked name='LOGIC' unlocked={0} />
            <SkillsUnlocked name='APP DEVELOPMENT' unlocked={0} />
            <SkillsUnlocked name='PYTHON' unlocked={0} />
            <SkillsUnlocked name='JAVA' unlocked={0} />
            <SkillsUnlocked name='HTML' unlocked={0} />
            <SkillsUnlocked name='CSS' unlocked={0} />
          </div>
        </div>
        <h4 className='mt-5'>Teacher Feedack</h4>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit,
          aspernatur perferendis! Praesentium debitis ipsum enim? Esse,
          quibusdam sint harum ipsam, magnam fugiat rem incidunt ipsa assumenda
          dolorum inventore! Repellendus, eaque?
        </p>
        <div className='d-flex justify-content-end'>
          <button>
            Download Report
            <span class='download_icon ml-2'>
              <BsDownload />
            </span>
          </button>
        </div>
      </div>
      
    </Base>
  )
}

export default MyReport
