import React, { useContext, useEffect, useState } from "react";
import "./Profile.css";
import { ImCross } from "react-icons/im";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import ProfileInput from "./Components/ProfileInput";
import BackNavigation from "../../Common/Components/BackNavigation";
import Base from "../../Common/Components/Base/Base";
import TkButton from "../../Common/Components/TkButton";
import DayPicker from "../../Common/Components/DayPicker";
import { UserContext } from "../../App";
import {
  getProfilePic,
  updateProfile,
  updateProfilePic,
} from "./ProfileAPIcalls";
import csc from "country-state-city";
import defaultProfilePic from "../../Assets/Profile/defaultProfilePic.jpg";
import { _arrayBufferToBase64 } from "../../Common/Utils/Helper";
import Overlay from "../../Common/Components/Overlay";
import StatusModal from "../../Common/Components/StatusModal";
import startOfYesterday from "date-fns/startOfYesterday/index";
const EditProfile = () => {
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });

  const [values, setValues] = useState({
    error: "",
    success: false,
  });

  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    city: "",
    state: "",
    country: "",
    dob: "",
    gender: "",
    about: "",
    parentPhone: {
      countryCode: null,
      number: null,
    },
    grade: 0,
    school: "",
    parentName: "",
    hobbies: "",
    profilePic: "",
    profilePicInfo: "",
  });

  const {
    name,
    email,
    city,
    state,
    country,
    dob,
    gender,
    parentPhone,
    about,
    grade,
    school,
    parentName,
    hobbies,
    profilePic,
    profilePicInfo,
  } = userInfo;

  const { error, success } = values;
  const [contactNo, setContactNo] = useState("");
  const [photoid, setPhotoid] = useState("");
  const [profilePicUri, setProfilePicUri] = useState("");
  const [date, setDate] = useState("");
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");

  useEffect(async () => {
    if (date != "") {
      console.log("date", typeof date);
      await setUserInfo((prev) => ({ ...prev, dob: date }));
    }
  }, [date]);
  const handleOnChange = (field) => (event) => {
    if (field === "country") {
      let data = event.target.value.split(" ");
      let code = data.pop(-1);
      setUserInfo({ ...userInfo, [field]: data.toString() });
      const list = csc.getStatesOfCountry(code);
      setCountryCode(code);
      console.log("list", list);
      setStateList(list);
      return;
    }
    if (field === "country") {
      let data = event.target.value.split(" ");
      let code = data.pop(-1);
      setUserInfo({ ...userInfo, [field]: data.toString() });
      const list = csc.getStatesOfCountry(code);
      setCountryCode(code);
      console.log("list", list);
      setStateList(list);
      return;
    }

    if (field == "state") {
      let data = event.target.value.split(" ");
      let code = data.pop(-1);
      console.log("xe data", code);
      setUserInfo({ ...userInfo, [field]: data.toString() });
      const list = csc.getCitiesOfState(countryCode, code);
      console.log("list", list);
      setCityList(list);
      return;
    }
    setUserInfo({ ...userInfo, [field]: event.target.value });
    // console.log("userInfo", userInfo);
  };

  const { userData, token, loading, setLoading } = useContext(UserContext);

  useEffect(() => {
    if (typeof contactNo !== "undefined") {
      setUserInfo({
        ...values,
        parentPhone: {
          // countryCode: parseInt(contactNo.toString().substring(0, 3)),
          number: parseInt(contactNo.toString()),
        },
        gender: userData.gender,
        grade: userData.grade,
      });
    }
  }, [contactNo]);

  useEffect(() => {
    setUserInfo({
      ...values,
      name: userData.name,
      email: userData.email,
      city: userData.city,
      state: userData.state,
      country: userData.country,
      dob: userData.dob,
      parentPhone: {
        countryCode: "91",

        number: userData.parentPhone.number.toString(),
      },
      gender: userData.gender,
      about: userData.about,
      grade: userData.grade,
      school: userData.school,
      parentName: userData.parentName,
      hobbies: userData.hobbies,
      profilePic: userData.profilePic,
    });
    setContactNo(userData.parentPhone.number.toString());
    setDate(userData.dob);
  }, [userData]);
  const history = useHistory();

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await onUpdateProfile();
  };

  const onPhotoUpload = () => {
    if (profilePicInfo != undefined) {
      updateProfilePic(profilePicInfo).then(
        (data) => {
          setUserInfo((prev) => ({ ...prev, profilePic: data.image }));
        },
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "success",
          statusText: "Photo Uploaded",
        })
      );
    } else {
      setStatusModal({
        ...statusModal,
        showStatusModal: true,
        status: "error",
        statusText: "Photo not selected",
      });
    }
  };

  useEffect(() => {
    if (!countryList) {
      setCountryList(csc.getAllCountries());
    }
  }, []);

  useEffect(() => {
    if (profilePic != undefined) {
      if (profilePicUri.substring(0, 4) != "blob") {
        getProfilePic(profilePic).then((binaryImg) => {
          let base64String =
            binaryImg != undefined && _arrayBufferToBase64(binaryImg.data);
          if (base64String) {
            const binary = `data:image/gif;base64,${base64String}`;
            setProfilePicUri(binary);
          } else {
            setProfilePicUri(defaultProfilePic);
          }
        });
      }
    }
  }, [profilePic, profilePicUri]);
  useEffect(() => {
    if (!countryList) {
      setCountryList(csc.getAllCountries());
    }
  }, []);
  const onUpdateProfile = async () => {
    await updateProfile(userInfo, token).then((data) => {
      window.location.reload();
    }, history.goBack());
  };

  const handleOnPhotoChange = (field) => (event) => {
    const value =
      field === "photo" ? event.target.files[0] : event.target.value;
    setProfilePicUri(URL.createObjectURL(value));
    setUserInfo({
      ...userInfo,
      profilePicInfo: value,
    });
  };

  return (
    <Base>
      <BackNavigation />

      <div className="profile_edit d-flex flex-sm-row flex-column w_90 mx-auto ">
        <div className="p_60 col-sm-6 col-12 d-flex align-items-center flex-column">
          {console.log(profilePic)}
          <img
            className="profile_pic"
            src={
              profilePicUri === undefined || profilePicUri === ""
                ? defaultProfilePic
                : profilePicUri
            }
            alt="profilepic"
          />

          <div className="d-flex justify-content-between profile_pic_option">
            <div className="upload_pic">
              <label>
                Select
                <input
                  type="file"
                  className="ticket_attach"
                  onChange={handleOnPhotoChange("photo")}
                  name="photo"
                  accept="image"
                />
              </label>
            </div>

            <button onClick={onPhotoUpload} className="upload_pic">
              <b>Upload</b>
            </button>
          </div>
          <ProfileInput
            label="Email"
            placeholder="Email"
            type="readonly"
            onChange={handleOnChange("email")}
            value={email}
          />
          <ProfileInput
            label="Country"
            type="selectCountry"
            placeholder="Country"
            onChange={handleOnChange("country")}
            value={country}
            countryList={countryList}
          />

          <ProfileInput
            label="State"
            type="selectState"
            placeholder="State"
            stateList={stateList}
            onChange={handleOnChange("state")}
            value={state}
          />
          <ProfileInput
            label="City"
            type="selectCity"
            placeholder="City"
            state={startOfYesterday}
            cityList={cityList}
            onChange={handleOnChange("city")}
            value={city}
          />
          <ProfileInput
            label="About Me"
            type="textarea"
            placeholder="About Me"
            onChange={handleOnChange("about")}
            value={about}
          />
        </div>
        <div className="p_60 col-sm-6 col-12 d-flex align-items-center flex-column">
          <ProfileInput
            label="Full Name"
            placeholder="Full Name"
            type="readonly"
            value={name}
            onChange={handleOnChange("name")}
          />

          <div className="profile_edit_input">
            <label>Contact no</label>

            <PhoneInput
              value={`+91${contactNo}`}
              onChange={setContactNo}
              defaultCountry="IN"
              name="phone"
              autoComplete="on"
              maxlength="15"
              readOnly
            />
          </div>

          <div className="profile_edit_input">
            <DayPicker label="DOB" date={date} setDate={setDate} />
          </div>

          <div className="profile_edit_input">
            <label>
              <b> Gender</b>
            </label>
            <ul
              className="d-flex profile_edit_radio"
              onChange={handleOnChange("gender")}
            >
              <li>
                <input
                  type="radio"
                  value="male"
                  name="gender"
                  id="radio1"
                  checked={gender == "male" && true}
                />
                <label for="radio1">Male</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="female"
                  name="gender"
                  id="radio2"
                  checked={gender == "female" && true}
                />
                <label for="radio2">Female</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="others"
                  name="gender"
                  id="radio3"
                  checked={gender == "others" && true}
                />
                <label for="radio3">Others</label>
              </li>
            </ul>
          </div>
          <div
            className="profile_edit_input"
            onChange={handleOnChange("grade")}
          >
            <label>
              <b> Grade</b>
            </label>
            <ul className="d-flex flex-column profile_edit_radio grade">
              <div className="d-flex justify-content-between">
                <li>
                  <input type="radio" checked={grade == 1 && true} />
                  <label>1</label>
                </li>
                <li>
                  <input type="radio" checked={grade == 2 && true} />
                  <label>2</label>
                </li>
                <li>
                  <input type="radio" checked={grade == 3 && true} />
                  <label>3</label>
                </li>
                <li>
                  <input type="radio" value="4" checked={grade == 4 && true} />
                  <label>4</label>
                </li>
                <li>
                  <input type="radio" value="5" checked={grade == 5 && true} />
                  <label>5</label>
                </li>
                <li>
                  <input type="radio" value="6" checked={grade == 6 && true} />
                  <label>6</label>
                </li>
              </div>

              <div className="d-flex justify-content-between">
                <li>
                  <input type="radio" value="7" checked={grade == 7 && true} />
                  <label>7</label>
                </li>
                <li>
                  <input type="radio" value="8" checked={grade == 8 && true} />
                  <label>8</label>
                </li>
                <li>
                  <input
                    type="radio"
                    value="9"
                    id="grade-radio9"
                    checked={grade == 9 && true}
                  />
                  <label>9</label>
                </li>
                <li>
                  <input
                    type="radio"
                    value="10"
                    checked={grade == 10 && true}
                  />
                  <label>10</label>
                </li>
                <li>
                  <input
                    type="radio"
                    value="11"
                    id="grade-radio11"
                    checked={grade == 11 && true}
                  />
                  <label for="grade-radio11">11</label>
                </li>
                <li>
                  <input
                    type="radio"
                    value="12"
                    id="grade-radio12"
                    checked={grade == 12 && true}
                  />
                  <label for="grade-radio12">12</label>
                </li>
              </div>
            </ul>
          </div>
          <ProfileInput
            label="School"
            type="text"
            placeholder="School"
            value={school}
            onChange={handleOnChange("school")}
          />
          <ProfileInput
            label="Guardian Name/Parents"
            type="text"
            placeholder="Guardian Name/Parents"
            value={parentName}
            onChange={handleOnChange("parentName")}
          />
          <ProfileInput
            label="Hobbies"
            type="textarea"
            placeholder="Hobbies"
            value={hobbies}
            onChange={handleOnChange("hobbies")}
          />
          <div className="w_90 d-flex justify-content-around">
            <TkButton
              name={"Save"}
              type={"submit"}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={(e) => onSubmit(e)}
            />
            <TkButton
              name={"Back"}
              type={"submit"}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() => history.goBack()}
            />
          </div>
        </div>
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() =>
            setStatusModal({ ...statusModal, showStatusModal: false })
          }
        />
      </div>
    </Base>
  );
};

export default EditProfile;
