import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InfoBox from "../../Common/Components/InfoBox";
import TkButton from "../../Common/Components/TkButton";
import { UserContext } from "../../App";
import { getClasses, getCurriculum } from "./Components/CoursesAPIcalls";

const PaidCourses = () => {
  const history = useHistory();
  const { userData, token } = useContext(UserContext);

  const [classes, setClasses] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  //console.log("usedata", userData);
  useEffect(async () => {
    var level = userData.level[6];
    var _package = userData.package;
    await getCurriculum(level, _package).then((res) => {
      console.log("curriculum", res);
      var data = res[0].lessons;
      var data2 = data.sort((a, b) => {
        return a.id - b.id;
      });
      setCurriculum(data2);
    });
    await getClasses(token).then((res) => {
      console.log("Paid classes", res);
      setClasses(res);
    });
  }, [token]);
  const countClass = (data) => {
    if (data == "Beginner") {
      return 8;
    } else if (data == "Advance") {
      return 48;
    } else if (data == "Master") {
      return 144;
    } else {
      return 300;
    }
  };

  const checkStatus = (item, index) => {
    var slot = classes[index];
    if (slot != undefined) {
      if (!slot.isActive) {
        return "Locked";
      } else {
        var classTime = new Date(slot.slot).getTime();
        var todayTime = new Date().getTime();
        var diffrence = classTime - todayTime;
        if (diffrence > 5 * 24 * 60 * 60 * 1000) {
          return "Unlocked";
        } else if (
          diffrence > 10 * 60 * 1000 &&
          diffrence < 5 * 24 * 60 * 60 * 1000
        ) {
          return "Scheduled";
        } else if (diffrence < 10 * 60 * 1000) {
          if (slot.isstudentjoined) {
            if (slot.paidClassINR || slot.paidClassEINR) {
              return "Completed";
            } else if (slot.halfCompleted || slot.cancelled) {
              return "Cancelled";
            } else {
              return "On Going";
            }
          } else {
            if (slot.paidClassINR || slot.paidClassEINR) {
              return "Completed";
            } else if (slot.halfCompleted || slot.cancelled) {
              return "Cancelled";
            } else {
              return "Join Now";
            }
          }
        }
      }
    } else {
      return "Locked";
    }
  };
  const countCompleted = () => {
    if (classes) {
      var count = 0;
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].isActive) {
          if (classes[i].paidClassEINR || classes[i].paidClassINR) {
            count += 1;
          }
        }
      }
      return count;
    } else return 0;
  };
  const countRemaining = () => {
    if (classes) {
      var count = 0;
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].isActive) {
          if (!(classes[i].paidClassEINR || classes[i].paidClassINR)) {
            count += 1;
          }
        }
      }
      return count;
    } else return 0;
  };
  return (
    <div className="w_85 mx-auto">
      <div className="shadow_bg  package mb-5 d-flex align-items-center justify-content-between">
        <span>Watch your freemium Videos</span>
        <TkButton
          name={"Watch Now"}
          style={{ backgroundColor: "#00B23B" }}
          _onBtnClick={() => history.push("/courses/free")}
        />
      </div>
      <div className="shadow_bg  package mb-5  d-flex justify-content-between">
        <div className=" d-flex flex-column">
          <span>Package Enrolled</span>
          <span className="text-danger font-weight-light mt-2">
            {userData.package} Plan
          </span>
        </div>
        <div className=" d-flex flex-column align-items-center">
          <span>Total Classes</span>
          <span className=" mt-2">
            <b>{countClass(userData.package)}</b>
          </span>
        </div>
        <div className=" d-flex flex-column align-items-center">
          <span>Completed</span>
          <span className="mt-2">
            <b>{countCompleted()}</b>
          </span>
        </div>
        <div className=" d-flex flex-column align-items-center">
          <span>Remaining</span>
          <span className="  mt-2">
            <b>{countRemaining()}</b>
          </span>
        </div>
      </div>

      {curriculum.map((item, index) => {
        return (
          <InfoBox
            name={item.id}
            subName={item.title}
            classID={classes[index] != undefined ? classes[index]._id : ""}
            desc={item.description}
            status={checkStatus(item, index)}
            classTime={classes[index] != undefined ? classes[index].slot : ""}
            meetingLink={
              classes[index] != undefined
                ? classes[index].teacherID != null
                  ? classes[index].teacherID.meetingLink
                  : ""
                : ""
            }
          />
        );
      })}
    </div>
  );
};

export default PaidCourses;
