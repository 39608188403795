import React from 'react'
import TkButton from '../../../Common/Components/TkButton'
import { openInNewTab } from '../../../Common/Utils/Helper'
import './Components.css'
import { base_route } from '../../../backend'
import CategoryIcon from '@material-ui/icons/Category'
import { useHistory } from 'react-router'

const ProjectCard = ({ projectData, isPreview }) => {
  const history = useHistory()
  let imgsrc = ''
  if (projectData.coverimage === '/static/media/boye.850b8e56.png') {
    imgsrc = '/static/media/boye.850b8e56.png'
  } else if (projectData.coverimage[0] === 'b') {
    imgsrc = projectData.coverimage
  } else {
    imgsrc = base_route + '/' + projectData.coverimage
  }
  const handleClick = () => {
    history.push({
      pathname: '/project/view',
      state: { projectData: projectData },
    })
  }
  return (
    // <div className="card project_card">
    //   <div class="card_item">
    //     <div class="card_image">
    //       <img src={imgsrc} alt="" />
    //     </div>
    //     <div className="project_info">
    //       <h6 className="text-wrap project_name">
    //         <b> {projectData.title}</b>
    //       </h6>
    //       <p className="text-wrap project_text">
    //         {`${projectData.description.substring(0, 150)}`}
    //       </p>
    //       {isPreview ? null : (
    //         <TkButton
    //           name={"View"}
    //           style={{backgroundColor: "#00b4db", width: 120, height: 30}}
    //           _class={"sm mt-2"}
    //           _onBtnClick={() => openInNewTab(projectData.projectlink)}
    //         />
    //       )}
    //     </div>
    //   </div>
    // </div>
    <div className='project_card' onClick={handleClick}>
      <div className='project_image_container'>
        <img src={imgsrc} alt='' />
      </div>
      <div className='project_info'>
        <p className='project_category'>
          <CategoryIcon style={{ color: '#00b5db' }} />
          <span>{projectData.category}</span>
        </p>
        <p className='project_title'>{projectData.title}</p>
        <p className='project_description'>
          {`${projectData.description.substring(0, 150)}....`}
        </p>
        {isPreview ? null : (
          <TkButton
            name={'View'}
            style={{ marginTop: '5px' }}
            _class={'sm mt-2 project_btn'}
            _onBtnClick={handleClick}
          />
        )}
      </div>
    </div>
  )
}

export default ProjectCard
