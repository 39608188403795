import React, {useEffect, useState} from "react";
import YouTube from "react-youtube";
import "./Components.css";
import Lock from "../../../Assets/Course/Lock.png";

const VideoCard = ({data, index, _class, currIndex}) => {
  const opts = {
    height: "200",
    width: "300",
    playerVars: {rel: 0},
  };

  const [videoId, setVideoId] = useState(null);

  const state = (event) => {
    currIndex();
    event.target.pauseVideo();
  };

  useEffect(() => {
    setVideoId(getVideoId(data.link)[2]);
  }, []);

  const getVideoId = (url) => {
    let urlId;
    if ((urlId = url.match(/(\?|&)v=([^&#]+)/))) {
      return urlId;
    } else if ((urlId = url.match(/(\.be\/)+([^\/]+)/))) {
      return urlId;
    } else if ((urlId = url.match(/(\embed\/)+([^\/]+)/))) {
      return urlId;
    }
  };

  return (
    <div className="card video_card">
      <div className={`${_class} `}>
        <img src={Lock} alt="" />
        <span className="mt-2">Locked</span>
        <p className="mt-2">Complete the previous video to unlock this</p>
      </div>
      <YouTube
        videoId={_class === "locked_video" ? "novideo" : videoId}
        onEnd={(e) => state(e)}
        opts={opts}
      />
    </div>
  );
};

export default VideoCard;
