import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import cirlce2 from "../../Assets/Badge/circle2.png";
import cirlce3 from "../../Assets/Badge/circle3.png";
import cirlce4 from "../../Assets/Badge/circle4.png";
import cirlce5 from "../../Assets/Badge/circle5.png";
import cirlce6 from "../../Assets/Badge/circle6.png";
import defaultPic from "../../Assets/Profile/default.jpg";
import {getProfilePic} from "../../Pages/Profile/ProfileAPIcalls";
import {_arrayBufferToBase64} from "../Utils/Helper";

const ProfileImgCover = () => {
  const {userData, token} = useContext(UserContext);
  const [imageSrc, setimageSrc] = useState(null);
  // let imageSrc
  //  = localStorage.getItem(`profile-url${userData.profilePic}`);

  useEffect(() => {
    if (userData.profilePic != undefined) {
      getProfilePic(userData.profilePic).then((binaryImg) => {
        let base64String =
          binaryImg != undefined && _arrayBufferToBase64(binaryImg.data);
        if (base64String) {
          const binary = `data:image/gif;base64,${base64String}`;
          setimageSrc(binary);
        } else {
          setimageSrc(defaultPic);
        }
      });
    }
  }, [userData.profilePic]);

  return (
    <div className="profile_pic_cover">
      <img src={cirlce2} alt="circle2" className="circle2" />
      <img src={cirlce3} alt="circle3" className="circle3" />
      <img src={cirlce4} alt="circle4" className="circle4" />
      <img src={cirlce5} alt="circle5" className="circle5" />
      <img src={cirlce6} alt="circle6" className="circle6" />
      <img
        className="profile_pic"
        src={
          imageSrc === undefined || imageSrc === null ? defaultPic : imageSrc
        }
        alt="profile-pic"
      />
    </div>
  );
};

export default ProfileImgCover;
