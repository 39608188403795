import React from "react";
import {Link} from "react-router-dom";
import "./components.css";
import {AiOutlineRight} from "react-icons/ai";

const SettingItem = ({item}) => {
  return (
    <>
      <Link className="setting_item" to={item.link}>
        <span>{item.title}</span>
        <AiOutlineRight />
      </Link>
    </>
  );
};

export default SettingItem;
