import React from "react";
import "./Components.css";
import Flag from "../../../Assets/Profile/Certificate/flag.png";
import Signature from "../../../Assets/Profile/Certificate/signature.png";
import Social from "../../../Assets/Profile/Certificate/social.png";
import Batch from "../../../Assets/Profile/Certificate/batch.png";

const Certificate = ({ name }) => {
  return (
    <div className="certificate_page" id="certificate">
      <div>
        <div className="certificate_title">
          <img src={Flag} alt="" className="certificate_blue_box" />
          <p>THIS CERTIFICATE IS PRESENTED TO</p>
        </div>
        <div className="certificate_name">
          <h1>{name}</h1>
          <p>
            is among the Top 20% of students to successfully complete the
            TechoKids Coding Trial.
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center px-4">
          <div className="certificate_signature">
            <img src={Signature} alt="" />
            <h5>Sonveer Singh</h5>
            <p>Co-Founder - TechoKids</p>
          </div>
          <div>
            <img className="certificate_batch" src={Batch} alt="" />
          </div>
        </div>
        <div className="certificate_alumni_container">
          <p className="certificate_alumni">
            TechoKds is created by the alumni of{" "}
          </p>
          <ul className="certificate_alumni_list">
            <li>IIT Bombay</li>
            <li>IIT Madras</li>
            <li>IIT Roorkee</li>
            <li>IIM Ahmedabad</li>
            <li>Google</li>
            <li>Facebook</li>
          </ul>
        </div>
        <div className="certificate_footer">
          <img src={Social} alt="" className="certificate_social" />
          <span>TechoKids.com</span>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
