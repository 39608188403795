import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../App'

import Base from '../../Common/Components/Base/Base'
import FreeDashboard from './FreeDashboard'
import PaidDashboard from './PaidDashboard'
import { getProfile, isAuthenticated } from '../../Pages/Login/LoginAPICalls'
import PasswordModal from './Components/PasswordModal'
import './Dashboard.css'
import StatusModal from '../../Common/Components/StatusModal'

const Dashboard = () => {
  const userInfo = useContext(UserContext)
  const { email, token } = isAuthenticated()
  const { isSetPasswordLater, setIsSetPasswordLater } = useContext(UserContext)
  // const [isSetPasswordLater, setIsSetPassWordLater] = useState(false)
console.log('isSetPasswordLater', isSetPasswordLater)
  const [successModal, setSuccessModal] = useState({
    successModal: true,
    successText: '',
  })

  const [passwordStatusModal, setPassworStatusModal] = useState({
    showStatusModal: false,
    status: '',
    statusText: '',
  })
  useEffect(() => {
    getUserProfile().then((data) => {
      userInfo.setProfileInfo(data)
    })
  }, [])

  const getUserProfile = () => {
    return getProfile(token)
      .then((response) => {
        if (response.parentPhone) {
          return response
        } else {
          alert("Can't get user")
        }
      })
      .catch((err) => {
        alert(err)
      })
      .finally(() => {
        userInfo.setLoading(false)
      })
  }

  return (
    <Base>
      {userInfo.payment ? <PaidDashboard /> : <FreeDashboard />}

      {userInfo.userData.password === '' && !isSetPasswordLater && (
        <PasswordModal
          showModal={successModal.successModal}
          closeModal={() =>
            setSuccessModal({ ...successModal, successModal: false })
          }
          email={userInfo.userData.email}
          token={userInfo.token}
          setPassworStatusModal={setPassworStatusModal}
          passwordStatusModal={passwordStatusModal}
          setIsSetPasswordLater={setIsSetPasswordLater}
          isSetPasswordLater={isSetPasswordLater}
        />
      )}
      <StatusModal
        showModal={passwordStatusModal.showStatusModal}
        text={passwordStatusModal.statusText}
        status={passwordStatusModal.status}
        closeModal={() => {
          setPassworStatusModal({
            ...passwordStatusModal,
            showStatusModal: false,
          })
        }}
      />
    </Base>
  )
}

export default Dashboard
