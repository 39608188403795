import React, {useState} from "react";
import "./TechSupport.css";
import phone from "../../Assets/TechSupport/phone.png";
import email from "../../Assets/TechSupport/email.png";
import pcsetup from "../../Assets/TechSupport/pcsetup.png";
import time from "../../Assets/TechSupport/time.png";
import chrome from "../../Assets/TechSupport/chrome.png";
import ContactInfoItem from "./Components/ContactInfoItem";
import {FiDownload} from "react-icons/fi";
import CheckPcModal from "./Components/CheckPcModal";
import {openInNewTab} from "../../Common/Utils/Helper";
import Base from "../../Common/Components/Base/Base";

const TechSupport = () => {
  const [showModal, setshowModal] = useState(false);

  const handleCheckPc = () => {
    setshowModal(true);
  };
  const onModalBtnClick = () => {
    setshowModal(!showModal);
  };
  return (
    <Base>
      <div className=" w_85 mx-auto">
        <div className="tech_support w_86 mx-auto">
          <ul>
            <li>
              <ContactInfoItem
                img={phone}
                title={"Phone"}
                text={"+91 88 965 18896"}
              />
            </li>
            <li>
              <ContactInfoItem
                img={email}
                title={"Email"}
                text={"support@techokids.com"}
              />
            </li>
            <li>
              <ContactInfoItem
                img={pcsetup}
                title={"Set up your PC"}
                text={
                  "Check your network connection and computer set up to have best in class experience."
                }
                action={{
                  title: "Check Pc",
                  handle: () => handleCheckPc(),
                }}
              />
            </li>
            <li>
              <ContactInfoItem
                img={time}
                title={"Download AnyDesk Here"}
                action={{
                  title: "Download",
                  handle: () => openInNewTab("https://anydesk.com/en"),
                  icon: <FiDownload />,
                }}
              />
            </li>
            <li>
              <ContactInfoItem
                img={chrome}
                title={"Download the latest version of chrome"}
                action={{
                  title: "Download",
                  handle: () =>
                    openInNewTab(
                      "https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAiA9vOABhBfEiwATCi7GM7k7e4RuMtyG53A4WpzO-Cl-VHG745bYZhcbrVOoZHFV-rjaeiS8RoC1XYQAvD_BwE&gclsrc=aw.ds"
                    ),
                  icon: <FiDownload />,
                }}
              />
            </li>
          </ul>
        </div>
        <CheckPcModal showModal={showModal} closeModal={onModalBtnClick} />
      </div>
    </Base>
  );
};

export default TechSupport;
