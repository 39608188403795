import {base_route} from "../../backend";

export const createProject = (data, token) => {
  console.log(" data at projec API", data);
  return fetch(`${base_route}/project/new`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "bearer-token": `${token}`,
    },
    body: data,
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getProjects = (token) => {
  return fetch(`${base_route}/project/userprojects`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
