import { base_route } from "../../backend";

export const updateProfile = (updateUserInfo, token) => {
  return fetch(`${base_route}/dashboard/user/updateProfile`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify(updateUserInfo),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getFreeSlot = (token) => {
  return fetch(`${base_route}/booking/teacherLink`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getClasses = (token) => {
  return fetch(`${base_route}/student/paidclasses`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const updateProfilePic = (file) => {
  console.log(file);
  const data = new FormData();
  data.append("image", file);
  data.append("name", file.name);
  data.append("desc", "desc");
  return fetch(`${base_route}/dashboard/user/uploadProfilePicture`, {
    method: "POST",
    body: data,
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getProfilePic = (id) => {
  return fetch(`${base_route}/dashboard/user/getProfilePicture/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
