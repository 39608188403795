import React, {useContext} from "react";
import Base from "../../Common/Components/Base/Base";
import FreeCourse from "./FreeCourse";
import PaidCourses from "./PaidCourses";
import {UserContext} from "../../App";

const Courses = () => {
  const userInfo = useContext(UserContext);

  return <Base>{userInfo.payment ? <PaidCourses /> : <FreeCourse />}</Base>;
};

export default Courses;
