import React from "react";
import {ImCross} from "react-icons/im";
import level from "../../../Assets/Profile/levels.jpg";

const BadgeModal = ({showModal, closeModal}) => {
  return (
    <div className={`modal badge_modal   ${showModal && "show_modal"}`}>
      <div className="modal_content ">
        <ImCross size={15} onClick={() => closeModal()} />
        <div className="d-flex justify-content-center">
          <img src={level} alt="level" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default BadgeModal;
