import { Link } from "@material-ui/core";
import React from "react";
import { ImCross } from "react-icons/im";

import certifiacte from "../../../Assets/Profile/certificate.jpeg";
import TkButton from "../../../Common/Components/TkButton";
import { FiDownload } from "react-icons/fi";
import Certificate from "./Certificate";
import AutoScale from "react-auto-scale";

const CertificateModal = ({
  showModal,
  closeModal,
  onDownloadBtnClick,
  name,
  userName,
}) => {
  return (
    <div className={`modal  certificate_modal  ${showModal && "show_modal"}`}>
      <div className="modal_content  ">
        <ImCross size={15} onClick={() => closeModal()} />
        <div
          className="d-flex justify-content-center align-items-center flex-column "
          style={{ height: "60vh" }}
        >
          <div className="certificate_modal_container">
            <Certificate name={userName(name)}></Certificate>
          </div>
          <TkButton
            name={"Download"}
            style={{ backgroundColor: "#60B91F" }}
            _class="mt-3 download_btn"
            Ricon={<FiDownload />}
            _onBtnClick={() => onDownloadBtnClick("")}
          />
        </div>
      </div>
    </div>
  );
};

export default CertificateModal;
