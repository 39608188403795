export const SettingsData = [
  {
    title: "Subscription",
    link: "/setting/subscription",
  },
  {
    title: "Change Password",
    link: "/setting/change/password",
  },
  {
    title: "Contact Us",
    link: "/setting/contactus",
  },
  {
    title: "Support Ticket",
    link: "/setting/support/ticket",
  },
];
