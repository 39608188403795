import React, {useEffect, useContext, useState} from "react";
import Base from "../../Common/Components/Base/Base";
import {BsCloudUpload} from "react-icons/bs";
import "./Project.css";
import ProjectCard from "./Components/ProjectCard";
import {projectData} from "./data";
import {useHistory} from "react-router-dom";
import {UserContext} from "../../App";
import {getProjects} from "./ProjectAPICalls";

const Project = () => {
  const history = useHistory();
  const {userData, token} = useContext(UserContext);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects(token).then((res) => {
      console.log("response of projects ", res);
      setProjects(res);
    });
  }, []);
  return (
    <Base>
      <div className="w_85 mx-auto">
        <div
          className="upload_project"
          onClick={() => history.push("project/upload")}
        >
          <BsCloudUpload size={20} />
          <span className="ml-3">Upload a project</span>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          {projects.map((data, index) => {
            return (
              <ProjectCard projectData={data} isPreview={!data.projectlink} />
            );
          })}
        </div>
      </div>
    </Base>
  );
};

export default Project;
