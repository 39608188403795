import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import {SidebarData} from "./SidebarData";
import Logo from "../../../Assets/Sidebar/logo.png";
import "./Base.css";
import {PaymentContext} from "../../../App";

const Base = ({className, children}) => {
  const [sidebar, setSidebar] = useState(false);

  let history = useHistory();
  const currentTab = (history, path) => {
    let currentPath = "/" + history.location.pathname.split("/")[1];

    if (currentPath === path) {
      return "active_link";
    } else {
      return "";
    }
  };

  const showSidebar = () => setSidebar(!sidebar);
  return (
    <div>
      <div className="navbar">
        <div className="menu-bars" onClick={showSidebar}>
          {sidebar ? <AiIcons.AiOutlineClose /> : <FaIcons.FaBars />}
        </div>

        <img src={Logo} alt="" className="img-fluid" />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-4 col-lg-2  sidebar ${
              sidebar ? "nav-menu active" : "nav-menu"
            }`}
          >
            <ul className="nav-menu-items " onClick={showSidebar}>
              <li className="nav-text m-4 web_logo">
                <img src={Logo} alt="" />
              </li>
              {SidebarData.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${item.cName} ${currentTab(
                      history,
                      item.path
                    )}`}
                  >
                    <Link to={item.path}>
                      {item.icon}
                      <span className="sidebar-title">{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <main className="col mx-auto">
            <div className={`main_content ${className}`}>{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};
export default Base;
