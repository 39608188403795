import React, { useState } from "react";
import "./Component.css";
import Rating from "@material-ui/lab/Rating";
import CloseIcon from "@material-ui/icons/Close";
import TkButton from "../../../Common/Components/TkButton";
import { addFeedback } from "../DashboardAPICalls";

const Feedback = ({
  setIsFeedbackBtnClicked,
  setFeedbackValues,
  feedbackValues,
  slotID,
  statusModal,
  setStatusModal,
}) => {
  const handleRadioClick = (e) => {
    e.target.name === "quality"
      ? setFeedbackValues((prevValues) => ({
          ...prevValues,
          quality: e.target.value,
        }))
      : setFeedbackValues((prevValues) => ({
          ...prevValues,
          interested: e.target.value,
        }));
  };

  const handleSubmit = async () => {
    if (
      !feedbackValues.quality ||
      !feedbackValues.interested ||
      !feedbackValues.suggestion
    ) {
      setStatusModal({
        ...statusModal,
        status: "error",
        showStatusModal: true,
        statusText: "All Fields Required",
      });
      return;
    }
    await addFeedback(slotID, feedbackValues).then((result) => {
      if (result.error) {
        setStatusModal({
          ...statusModal,
          status: "error",
          showStatusModal: true,
          statusText: result.error,
        });
        return;
      } else {
        setIsFeedbackBtnClicked(false);
        setStatusModal({
          ...statusModal,
          status: "success",
          showStatusModal: true,
          statusText: result.message,
        });
        window.location.reload();
        return;
      }
    });
  };

  return (
    <div className="feedback_container">
      <span
        className="feedback_close_icon"
        onClick={() => setIsFeedbackBtnClicked(false)}
      >
        <CloseIcon fontSize="large"></CloseIcon>
      </span>
      <div className="feedback_check_container">
        <p>How was your class</p>

        <label htmlFor="poor">Poor</label>
        <input
          type="radio"
          name="quality"
          id=""
          value="Poor"
          onClick={handleRadioClick}
        />
        <label htmlFor="good">Good</label>
        <input
          type="radio"
          name="quality"
          id=""
          value="Good"
          onClick={handleRadioClick}
        />
        <label htmlFor="excellent">Excellent</label>
        <input
          type="radio"
          name="quality"
          id=""
          value="Excellent"
          onClick={handleRadioClick}
        />
      </div>
      <div className="feedback_check_container">
        <p>Are you interested in learning more about coding?</p>
        <label htmlFor="no">No</label>
        <input
          type="radio"
          name="interested"
          id=""
          value="No"
          onClick={handleRadioClick}
        />
        <label htmlFor="maybe">Maybe</label>
        <input
          type="radio"
          name="interested"
          id=""
          value="Maybe"
          onClick={handleRadioClick}
        />
        <label htmlFor="yes">Yes</label>
        <input
          type="radio"
          name="interested"
          id=""
          value="Yes"
          onClick={handleRadioClick}
        />
      </div>
      <div className="feedback_rating_container">
        <div className="feedback_rating_title">
          <p>Teacher Rating</p>
          <h3>Overall Rating</h3>
        </div>
        <div className="feedback_rating">
          <span>
            <Rating
              name="half-rating"
              precision={0.5}
              // value={teacherRating}
              onChange={(event, newValue) => {
                setFeedbackValues((prevValues) => ({
                  ...prevValues,
                  teacherRating: newValue,
                }));
              }}
            ></Rating>
          </span>
          <br />
          <span>
            <Rating
              name="half-rating-teacher"
              precision={0.5}
              size="large"
              // value={overallRating}
              onChange={(event, newValue) => {
                setFeedbackValues((prevValues) => ({
                  ...prevValues,
                  overallRating: newValue,
                }));
              }}
            ></Rating>
          </span>
        </div>
      </div>
      <div className="feedback_suggestion">
        <p>What do you suggest to improve our trial class?</p>
        <textarea
          name="suggestion"
          id=""
          cols="30"
          rows="3"
          onChange={(e) => {
            setFeedbackValues((prevValues) => ({
              ...prevValues,
              suggestion: e.target.value,
            }));
          }}
        ></textarea>
      </div>
      <span onClick={handleSubmit}>
        <TkButton _class="feedback_done_btn" name="Done"></TkButton>
      </span>
    </div>
  );
};

export default Feedback;
