import React from "react";
import { ReactComponent as Dashbord } from "../../../Assets/Sidebar/dashboard.svg";
import { ReactComponent as Invite } from "../../../Assets/Sidebar/invite.svg";
import { ReactComponent as Courses } from "../../../Assets/Sidebar/courses.svg";
import { ReactComponent as Setting } from "../../../Assets/Sidebar/setting.svg";
import { ReactComponent as Rocket } from "../../../Assets/Sidebar/rocket.svg";
import { ReactComponent as Support } from "../../../Assets/Sidebar/support.svg";
import { ReactComponent as Profile } from "../../../Assets/Sidebar/profile.svg";
import { ReactComponent as Report } from "../../../Assets/Sidebar/Report.svg";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <Dashbord />,
    cName: "nav-text",
  },
  {
    title: "Courses",
    path: "/courses",
    icon: <Courses />,
    cName: "nav-text",
  },
  {
    title: "Profile",
    path: "/profile",
    icon: <Profile />,
    cName: "nav-text",
  },
  {
    title: "Your Report",
    path: "/report",
    icon: <Report />,
    cName: "nav-text",
  },
  {
    title: "Project",
    path: "/project",
    icon: <Rocket />,
    cName: "nav-text",
  },
  {
    title: "Tech Support",
    path: "/support",
    icon: <Support />,
    cName: "nav-text",
  },
  {
    title: "Invite & Win",
    path: "/referral",
    icon: <Invite />,
    cName: "nav-text",
  },
  {
    title: "Setting",
    path: "/setting",
    icon: <Setting />,
    cName: "nav-text",
  },
];
