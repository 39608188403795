import React from "react";
import Base from "../../../Common/Components/Base/Base";
import BackNavigation from "../../../Common/Components/BackNavigation";
import "./Styles.css";
import phone from "../../../Assets/TechSupport/phone.png";
import email from "../../../Assets/TechSupport/email.png";
import address from "../../../Assets/TechSupport/address.png";
import ContactInfoItem from "../Components/ContactInfoItem";

const ContactUs = () => {
  return (
    <Base>
      <div className="w_85 mx-auto">
        <BackNavigation
          title={"Reach out to us for any assistance"}
          color={"#000"}
        />

        <div className="tech_support w_86 mx-auto">
          <ul>
            <li>
              <div className="contact_info_item">
                <span className="contact_info_icon">
                  <img src={address} alt="" />
                </span>

                <div className="contact_info_info">
                  <span className="contact_info_title">
                    <b>Address</b>
                  </span>
                  <span className="contact_info_text mt-3">
                    {" "}
                    <b> Asian region Headquarter: </b> Siddharth Complex,
                    9th-911,912, RC Dutt Rd, Alkapuri, Vadodara, Gujarat, India
                    390007{" "}
                  </span>
                  <span className="contact_info_text">
                    {" "}
                    <b> USA Headquarter:</b> 2554 Ashmore Circle, Thousand Oaks
                    CA USA 91362
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="contact_info_item">
                <span className="contact_info_icon">
                  <img src={phone} alt="" />
                </span>

                <div className="contact_info_info">
                  <span className="contact_info_title">
                    <b>Phone</b>
                  </span>
                  <span className="contact_info_text">+91 265-3561742</span>
                  <span className="contact_info_text">+91 88 965 18896</span>
                </div>
              </div>
            </li>
            <li>
              <ContactInfoItem
                img={email}
                title={"Email"}
                text={"support@techokids.com"}
              />
            </li>
          </ul>
        </div>
      </div>
    </Base>
  );
};

export default ContactUs;
