import React from "react";
import TkButton from "../../Common/Components/TkButton";
import TkSection from "../../Common/Components/TkSection";
import WatchNow from "../../Assets/Dashboard/WatchNow.png";
import {useHistory} from "react-router-dom";
import PricingCard from "../../Common/Components/PricingCard/PricingCard";

const FreeCourse = () => {
  const history = useHistory();
  return (
    <div className="w_85 mx-auto">
      <TkSection
        text={
          <>
            <h6 className="heading">
              <b>Haven't bought our courses</b>
            </h6>
            <span className="mt-2 desc">
              You can always learn from our freemium course
            </span>
          </>
        }
        Btn={
          <TkButton
            name={"WATCH NOW"}
            style={{backgroundColor: "#60B91F", width: 210}}
            _class="my-3"
            _type="submit"
            _onBtnClick={() => history.push("/courses/free")}
          />
        }
        img={WatchNow}
      />

      <PricingCard />
    </div>
  );
};

export default FreeCourse;
