import { base_route } from "../../backend";
import React, { useEffect, useState } from "react";
import "./commonComponents.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import TkButton from "./TkButton";
import { useHistory } from "react-router-dom";
import calendarImg from "../../Assets/Course/calendarImg.png";
import Lock from "../../Assets/Course/Lock.png";
import Completed from "../../Assets/Course/Completed.png";
import Cancelled from "../../Assets/Course/Cancelled.png";
const dev = "http://localhost:8000";
// const prod = "https://techokids.herokuapp.com";
const baseUrl = dev;
const InfoBox = ({
  name,
  subName,
  desc,
  status,
  _onBtnClick,
  classTime,
  classID,
  meetingLink,
}) => {
  const [expand, setExpand] = useState(false);
  const updateJoined = async () => {
    await fetch(`${base_route}/slot/updatestudentjoined`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ classID }),
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log("Response", resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let result;
  const history = useHistory();
  const iconView = () => {
    switch (status) {
      case "Join Now":
        result = (
          <div className="info_box_status">
            <TkButton
              name={status}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() => {
                updateJoined();
                window.open(meetingLink);
              }}
            />
          </div>
        );
        break;
      case "On Going":
        result = (
          <div className="info_box_status">
            <TkButton
              name={status}
              style={{ backgroundColor: "#e6ca17" }}
              _onBtnClick={() => window.open(meetingLink)}
            />
          </div>
        );
        break;
      case "Completed":
        result = (
          <div className="info_box_status d-flex align-items-center">
            <img src={Completed} alt="calendar" className="mr-2 calendar_img" />
            <span className="text-success">Completed</span>
          </div>
        );
        break;
      case "Cancelled":
        result = (
          <div className="info_box_status d-flex align-items-center">
            <img
              src={Cancelled}
              alt="calendar"
              className="mr-2 calendar_img"
              width="48px"
            />
            <span className="text-error">Cancelled</span>
          </div>
        );
        break;

      case "Scheduled":
        result = (
          <div className="info_box_status d-flex align-items-center">
            <img
              src={calendarImg}
              alt="calendar"
              className="mr-2 calendar_img"
            />
            <span>{classTime.slice(4, 10) + ", " + classTime.slice(16)}</span>
          </div>
        );
        break;
      case "Locked":
        result = (
          <div className="info_box_status d-flex align-items-center">
            <img src={Lock} alt="calendar" className="mr-2 calendar_img" />
            <span>Locked</span>
          </div>
        );
        break;
      case "Unlocked":
        result = (
          <div className="info_box_status d-flex align-items-center">
            <span>Unlocked</span>
            <>
              {expand ? (
                <IoMdArrowDropup
                  size={40}
                  color={"#00b4db"}
                  onClick={() => setExpand(false)}
                />
              ) : (
                <IoMdArrowDropdown
                  size={40}
                  color={"#00b4db"}
                  onClick={() => setExpand(true)}
                />
              )}
            </>
          </div>
        );
        break;
      default:
        result = (
          <TkButton
            name={status}
            style={{ backgroundColor: "#00B23B" }}
            _onBtnClick={_onBtnClick}
          />
        );
        break;
    }
    return result;
  };

  return (
    <div className="info_box">
      <div className="info_box_container  d-flex justify-content-between align-items-center mt-3">
        <span className="info_box_name">{name}</span>
        <span className="tinfo_box_name">{subName}</span>
        {iconView()}
      </div>
      {expand && (
        <div className="expand_text">
          <span>{desc}</span>
        </div>
      )}
    </div>
  );
};

export default InfoBox;
